import axios from "axios";
import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import { Layout } from "./Layout";
import DashboardPage from "./pages/DashboardPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import { GlobalProvider } from "./providers/GlobalContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Auth0ProviderWithHistory from "./Auth0ProviderWithHistory";

export interface AppSettings {
    Auth0: {
        Domain: string;
        ClientId: string;
        Audience: string;
    };
    Api: {
        BombApi: string;
        StakingApi: string;
    };
    Transak: {
        ApiKey: string;
        Environment: string;
    };
    Google: {
        Recaptcha: {
            EmailSubscribeKey: string;
        };
    };
    Settings: {
        MinimumInvoiceAmount: number;
    };
}

function App() {
    const [appSettings, setAppSettings] = useState<AppSettings | null>();

    useEffect(() => {
        axios
            .get("https://bmbombbtc.blob.core.windows.net/environments/production.json")
            .then((resp) => {
                const appSettings: AppSettings = resp.data;
                setAppSettings(appSettings);
            })
            .catch(() => {
                //todo:handle no settings
            });
    }, []);

    return appSettings ? (
        <BrowserRouter>
            <Auth0ProviderWithHistory appSettings={appSettings}>
                <GlobalProvider appSettings={appSettings}>
                    <HelmetProvider>
                        <ToastContainer enableMultiContainer />
                        <Layout>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/dashboard" element={<DashboardPage />} />
                            </Routes>
                        </Layout>
                    </HelmetProvider>
                </GlobalProvider>
            </Auth0ProviderWithHistory>
        </BrowserRouter>
    ) : (
        <></> //todo: handle no settings
    );
}

export default App;
