import React from "react";

interface CalculationProps {
    apr: number | null;
    calcValue?: number;
    setCalcValue: (amount: number) => void;
}

function Calculation(props: CalculationProps) {
    const stakeAmount = props?.calcValue ?? 1;
    const bonus = Number.isNaN(props.calcValue) ? 0 : stakeAmount * 0.05;

    const compoundInterest = (principal: number, annualRate: number, compoundsPerYear: number, months: number) => {
        if (!principal || !annualRate || !compoundsPerYear || !months) return 0;
        return principal * (1 + annualRate / compoundsPerYear) ** (compoundsPerYear * (months / 12));
    };

    const aprAsDecimal = props.apr && props.apr / 100;

    const sixMonths = aprAsDecimal && compoundInterest(stakeAmount, aprAsDecimal, 2, 6) + bonus;
    const oneYear = aprAsDecimal && compoundInterest(stakeAmount, aprAsDecimal, 2, 12) + bonus;
    const twoYears = aprAsDecimal && compoundInterest(stakeAmount, aprAsDecimal, 2, 24) + bonus;

    return (
        <div>
            <div className="grid grid-cols-2 gap-4 md:w-4/6">
                <div className="">
                    <div className="text-primary font-semibold text-lg text-center mb-2">Initial Deposit</div>
                    <div className="relative">
                        <div className="text-white absolute h-fit left-4 top-0 bottom-0 my-auto">$</div>
                        <input
                            type="number"
                            step={0.01}
                            value={stakeAmount}
                            onChange={(x) => props.setCalcValue(x.target.valueAsNumber)}
                            className="border border-slate-300 w-full text-white pl-6 py-2 bg-slateblue rounded-full shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 overflow-auto scrollbar scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-slate-700"
                        />
                    </div>
                </div>
                <div className="">
                    <div className="text-primary font-semibold text-lg text-center mb-2">Bonus Received</div>
                    <div className="relative items-center px-4 py-2 border-2 border-primary shadow-sm font-medium rounded-lg text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase overflow-auto scrollbar scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-slate-700">
                        $
                        {bonus.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                    </div>
                </div>
                <div className="text-primary col-span-2">Estimated* total value after:</div>
                <div className="">
                    <div className="relative items-center px-4 py-2 border-2 border-primary shadow-sm font-medium rounded-lg text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase overflow-auto scrollbar scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-slate-700">
                        $
                        {sixMonths &&
                            sixMonths?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}
                    </div>
                </div>
                <div className="text-primary self-center">6 Months</div>
                <div className="">
                    <div className="relative items-center px-4 py-2 border-2 border-primary shadow-sm font-medium rounded-lg text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase overflow-auto scrollbar scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-slate-700">
                        $
                        {oneYear &&
                            oneYear?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                    </div>
                </div>
                <div className="text-primary self-center">12 Months</div>
                <div className="">
                    <div className="relative items-center px-4 py-2 border-2 border-primary shadow-sm font-medium rounded-lg text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase overflow-auto scrollbar scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-slate-700">
                        $
                        {twoYears &&
                            twoYears?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                    </div>
                </div>
                <div className="text-primary self-center">24 Months</div>
                <div className="text-primary col-span-2 italic">*Calculated from live variable APR.</div>
            </div>
        </div>
    );
}

export default Calculation;
