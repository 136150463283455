import React, { useCallback } from "react";
import Moment from "react-moment";
import Flame from "../../images/flame.png";
import Minus from "../../images/blueminus.png";
import Plus from "../../images/greenplus.png";

interface ActivityProps {
    type: ActivityType;
    stakedAt: Date;
    btcAmount: number | string;
    usdAmount: number | string;
}

export enum ActivityType {
    Stake,
    Deposit,
    Withdrawal,
}

interface activitySettings {
    iconBackgroundColor: string;
    statusTextColor: string;
    statusBackground: string;
    statusText: string;
    icon: string;
    iconBorderColor: string;
}

function Activity(props: ActivityProps) {
    const getSettings = useCallback(() => {
        switch (props.type) {
            case ActivityType.Deposit:
                return {
                    iconBackgroundColor: "bg-[#274A46]",
                    iconBorderColor: "border-[#2B7258]",
                    statusBackground: "bg-[#274A46]",
                    statusTextColor: "text-[#1CBD65]",
                    icon: Plus,
                    statusText: "Deposit",
                } as activitySettings;
            case ActivityType.Stake:
                return {
                    iconBackgroundColor: "bg-[#6E6535]",
                    iconBorderColor: "border-[#A79011]",
                    statusBackground: "bg-[#6E6535]",
                    statusTextColor: "text-[#FFD603]",
                    icon: Flame,
                    statusText: "Stake",
                } as activitySettings;
            case ActivityType.Withdrawal:
                return {
                    iconBackgroundColor: "bg-[#104674]",
                    iconBorderColor: "border-[#1D607E]",
                    statusBackground: "bg-[#104674]",
                    statusTextColor: "text-[#1292E3]",
                    icon: Minus,
                    statusText: "Withdraw",
                } as activitySettings;
        }
    }, [props.type]);

    const settings = getSettings();

    return (
        <div className="bg-[#1C283C] rounded-lg px-2 py-2 flex gap-2 items-center">
            <div
                className={`${settings.iconBackgroundColor} ${settings.iconBorderColor} h-10 w-10 rounded-full p-2 border flex items-center`}
            >
                <img className="" src={settings.icon} alt="Activity Icon" />
            </div>
            <div className="flex-1">
                <div className="text-white text-base font-medium leading-tight">
                    {props.btcAmount.toLocaleString(undefined, {
                        maximumFractionDigits: 9,
                    })}{" "}
                    <span className="text-xs">YB</span>BTC
                </div>
                <div className="text-slate-500 text-base">
                    $
                    {props.usdAmount.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                    })}
                </div>
            </div>
            <div className="flex flex-col">
                <div
                    className={`${settings.statusBackground} ${settings.statusTextColor} px-2 text-center rounded-full font-medium text-base leading-snug`}
                >
                    {settings.statusText}
                </div>
                <div className="text-slate-500 text-sm text-right">
                    <Moment format="MMM DD, yyyy">{props.stakedAt}</Moment>
                </div>
            </div>
        </div>
    );
}

export default Activity;
