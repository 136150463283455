import React from "react";
import "./Scroller.scss";

interface ScrollerProps {
    className?: string;
    onClick?: () => void;
}

function Scroller(props: ScrollerProps) {
    return (
        <div
            className={
                "scroller" + (props.className ? ` ${props.className}` : "")
            }
            onClick={props.onClick}
        >
            <div className="mouse">
                <div className="wheel"></div>
            </div>
            <div>
                <span className="scroller_arrows first"></span>
                <span className="scroller_arrows second"></span>
                <span className="scroller_arrows third"></span>
            </div>
        </div>
    );
}

export default Scroller;
