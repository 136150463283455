import { Dispatch, FC, SetStateAction } from "react";
import { Dialog } from "@headlessui/react";
import PopupBase from "../popup/PopupBase";

interface StakePopupProps {
    className?: string;
    amount: number;
    durationHours: number;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    goBack: () => void;
    proceed: () => void;
}

const StakePopup: FC<StakePopupProps> = (props) => {
    return (
        <PopupBase onClose={props.goBack} open={props.open} className={props.className}>
            <div className="">
                <div className="text-white">
                    <Dialog.Title as="h3" className="">
                        <div>
                            <div className="text-center text-lg mb-2">
                                You will have {props.durationHours} hours to deposit{" "}
                                <span className="font-bold">{props.amount}</span> BTC.
                            </div>
                        </div>
                    </Dialog.Title>
                    <div className="my-4">
                        <ul className="list-['💣'] space-y-3 list-inside ">
                            <li>
                                <span className="pl-3">Staked funds can be withdrawn at any time.</span>
                            </li>
                            <li>
                                <span className="pl-3">Rewards accumulate daily.</span>
                            </li>
                            <li>
                                <span className="pl-3">Early withdrawals forfeit a portion of rewards.</span>
                            </li>
                        </ul>
                    </div>
                    <a
                        className="italic text-blue-500"
                        href="https://chat.bomb.app/hc/bomb-btc-help/en/bomb-btc-single-stake-in-depth"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Learn more!
                    </a>
                </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    className="relative justify-self-center items-center px-6 py-2 border border-transparent shadow-sm font-medium rounded-full text-white bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-800 uppercase text-center"
                    onClick={props.goBack}
                >
                    Go back
                </button>
                <button
                    onClick={props.proceed}
                    className="relative justify-self-center items-center px-6 py-2 border border-transparent shadow-sm font-medium rounded-full text-darkblue bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase disabled:border-[#707E96] disabled:text-[#1B2535] disabled:bg-[#707E96]"
                >
                    Proceed
                </button>
            </div>
        </PopupBase>
    );
};

export default StakePopup;
