import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useRef, useState } from "react";
import { storageStakeName } from "../../Auth0ProviderWithHistory";
import { useGlobalContext } from "../../providers/GlobalContext";
import StakePopup from "./StakePopup";

interface StakeInputProps {
    outline?: boolean;
    glow?: boolean;
    btnText?: string;
    className?: string;
    redirectTo?: string;
    stakeAmount: number;
    setStakeAmount: (amount: number) => void;
}
const MINIMUM_STAKE_AMOUNT = 0.001;

function StakeInput(props: StakeInputProps) {
    const divRef = useRef<HTMLDivElement>(null);
    const { createStake, appSettings } = useGlobalContext();
    const [openStakePopup, setOpenStakePopup] = useState(false);

    const [minimumStakeAmount, setMinimumStakeAmount] = useState<number>(MINIMUM_STAKE_AMOUNT);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [divWidth, setDivWidth] = useState<number>();
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    const minimumInvoiceAmount = appSettings?.Settings.MinimumInvoiceAmount;
    const setStakeAmount = props.setStakeAmount;

    //updates the stake amount & min stake amount using app settings
    useEffect(() => {
        if (minimumInvoiceAmount && setStakeAmount) {
            setMinimumStakeAmount(minimumInvoiceAmount ?? MINIMUM_STAKE_AMOUNT);
            setStakeAmount(minimumInvoiceAmount ?? MINIMUM_STAKE_AMOUNT);
        }
    }, [minimumInvoiceAmount, setStakeAmount]);

    //updates div width used to offset useable input space
    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            // Depending on the layout, you may need to swap inlineSize with blockSize
            // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
            setDivWidth(event[0].contentBoxSize[0].inlineSize);
        });

        let observerRefValue: HTMLDivElement | null = null;
        if (divRef && divRef.current) {
            resizeObserver.observe(divRef.current);
            observerRefValue = divRef.current;
        }

        return () => {
            if (observerRefValue) {
                resizeObserver.unobserve(observerRefValue);
            }
        };
    }, [divRef]);

    //reads the stakeAmount from storage and processes stake if possible
    useEffect(() => {
        if (isAuthenticated) {
            const amount = localStorage.getItem(storageStakeName);
            if (!!amount) {
                setStakeAmount(parseFloat(amount));
                setOpenStakePopup(true);
            }
        }
    }, [isAuthenticated, createStake, setStakeAmount, minimumStakeAmount]);

    const handleSubmit = () => {
        if (submitted || props.stakeAmount <= 0) return;
        setSubmitted(true);
        if (isAuthenticated) {
            setOpenStakePopup(true);
        } else {
            loginWithRedirect({ appState: { stakeAmount: props.stakeAmount, returnTo: props.redirectTo } });
        }
    };

    return (
        <div>
            <StakePopup
                amount={props.stakeAmount}
                proceed={() => {
                    createStake(props.stakeAmount);
                    setSubmitted(false);
                    setOpenStakePopup(false);
                    props.setStakeAmount(minimumStakeAmount);
                    localStorage.removeItem(storageStakeName);
                }}
                goBack={() => {
                    setOpenStakePopup(false);
                    setSubmitted(false);
                    localStorage.removeItem(storageStakeName);
                }}
                durationHours={24}
                setOpen={setOpenStakePopup}
                open={openStakePopup}
            />
            <div
                className={
                    "flex flex-row items-center relative text-md w-full max-w-full" +
                    (props.className ? ` ${props.className}` : "")
                }
            >
                <input
                    type="number"
                    step={0.00000000001}
                    value={props.stakeAmount}
                    min={minimumStakeAmount}
                    onChange={(e) => {
                        props.setStakeAmount(e.currentTarget.valueAsNumber);
                        setSubmitted(false);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            handleSubmit();
                        }
                    }}
                    style={{ paddingRight: divWidth }}
                    className={
                        (props.outline ? "border border-slate-300 " : "") +
                        "w-full text-white pl-6 py-2 bg-slateblue  rounded-full shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    }
                />
                <div className="absolute right-0" ref={divRef}>
                    <span className="text-white mr-3">BTC</span>
                    <button
                        className={
                            (props.glow &&
                            !(props.stakeAmount < minimumStakeAmount || submitted || Number.isNaN(props.stakeAmount))
                                ? "shadow-glow shadow-primary "
                                : "") +
                            "relative inline-flex items-center px-6 py-2 border border-transparent shadow-sm font-medium rounded-full text-darkblue bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase disabled:border-[#707E96] disabled:text-[#1B2535] disabled:bg-[#707E96]"
                        }
                        disabled={
                            props.stakeAmount < minimumStakeAmount || submitted || Number.isNaN(props.stakeAmount)
                        }
                        onClick={handleSubmit}
                    >
                        {props.btnText ? props.btnText : "Stake Now"}
                    </button>
                </div>
            </div>
            {props.stakeAmount < minimumStakeAmount ? (
                <div className="text-red-500 text-sm px-4 pt-1 ">Minimum stake amount is {minimumStakeAmount} BTC.</div>
            ) : null}
        </div>
    );
}

export default StakeInput;
