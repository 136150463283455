import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useGlobalContext } from "../../providers/GlobalContext";
import PopupBase from "../popup/PopupBase";

interface WithdrawButtonProps {
    className?: string;
    forfeitAmount: number;
    stakeAmount: number;
    earlyWithdrawalPenaltyAmount: number;
    rewardAmount: number;
    setOpen: Dispatch<SetStateAction<boolean>>;
    open: boolean;
    stakeId: string;
    isBeforeMaturity?: boolean;
}

type WithdrawalInputs = {
    walletAddress: string;
    accept: boolean;
};

const WithdrawPopup: FC<WithdrawButtonProps> = (props) => {
    const { withdrawStake } = useGlobalContext();
    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm<WithdrawalInputs>();

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleConfirm: SubmitHandler<WithdrawalInputs> = (data) => {
        withdrawStake(props.stakeId, data.walletAddress);
        handleClose();
    };

    useEffect(() => {
        clearErrors();
        reset();
    }, [props.open, clearErrors, reset]);

    return (
        <PopupBase onClose={() => props.setOpen(false)} open={props.open} className={""}>
            <form onSubmit={handleSubmit(handleConfirm)}>
                <div className="">
                    <div className="text-white">
                        <Dialog.Title as="h3" className="">
                            <div>
                                <div className="text-center text-lg mb-2">Withdraw Stake</div>
                                <div>
                                    <div className="mb-2">
                                        {props.isBeforeMaturity
                                            ? "Your early withdrawal is calculated as follows:"
                                            : " Your withdrawal is calculated as follows:"}
                                    </div>
                                    <div className="flex gap-4 flex-wrap justify-items-center text-center items-center justify-evenly mb-2">
                                        <span className="text-xs md:text-lg self-start ">
                                            <div>
                                                $
                                                {props.stakeAmount.toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                })}
                                            </div>
                                            <div className="text-xs max-w-[4rem]">Staked Value</div>
                                        </span>
                                        <span className="w-1">+</span>
                                        <span className="text-xs md:text-lg self-start">
                                            <div>
                                                $
                                                {props.rewardAmount.toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                })}
                                            </div>
                                            <div className="text-xs max-w-[4rem]">Rewards Value</div>
                                        </span>

                                        {props.isBeforeMaturity && (
                                            <>
                                                <span className="w-1">-</span>
                                                <span className="text-xs md:text-lg self-start">
                                                    <div>
                                                        $
                                                        {props.forfeitAmount.toLocaleString(undefined, {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                        })}
                                                    </div>
                                                    <div className="text-xs max-w-[4rem]">
                                                        Forfeited Reward Value
                                                        <a
                                                            href="https://chat.bomb.app/hc/bomb-btc-help/en/bomb-btc-single-stake-in-depth/12"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="text-blue-500 text-sm ml-2"
                                                        >
                                                            ?
                                                        </a>
                                                    </div>
                                                </span>
                                                <span className="w-1">-</span>
                                                <span className="text-xs md:text-lg self-start">
                                                    <div>
                                                        $
                                                        {props.earlyWithdrawalPenaltyAmount.toLocaleString(undefined, {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                        })}
                                                    </div>
                                                    <div className="text-xs max-w-[4rem]">
                                                        Early Withdrawal Fee
                                                        <a
                                                            href="https://chat.bomb.app/hc/bomb-btc-help/en/deposits-and-withdrawals/13"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="text-blue-500 text-sm ml-2"
                                                        >
                                                            ?
                                                        </a>
                                                    </div>
                                                </span>
                                            </>
                                        )}

                                        <span className="w-1">=</span>
                                        <span className="text-xs md:text-lg self-start">
                                            <div>
                                                $
                                                {(props.isBeforeMaturity
                                                    ? props.stakeAmount +
                                                      props.rewardAmount -
                                                      props.forfeitAmount -
                                                      props.earlyWithdrawalPenaltyAmount
                                                    : props.stakeAmount + props.rewardAmount
                                                ).toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                })}
                                            </div>
                                            <div className="text-xs max-w-[4rem]">Total Value</div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Title>
                        {props.isBeforeMaturity && (
                            <>
                                <div className="mt-6 flex ">
                                    <input
                                        {...register("accept", { required: true })}
                                        id="agree-checkbox"
                                        aria-describedby="checkbox-1"
                                        type="checkbox"
                                        className="w-5 h-5 shrink-0 text-blue-600 bg-gray-800 rounded"
                                    />
                                    <label
                                        className={"ml-3 text-sm font-medium text-gray-300"}
                                        htmlFor="agree-checkbox"
                                    >
                                        I agree that I may receive more or less than my quoted withdrawal amount.{" "}
                                        <a
                                            href="https://chat.bomb.app/hc/bomb-btc-help/en/deposits-and-withdrawals/13"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500"
                                        >
                                            Learn more here
                                        </a>
                                    </label>
                                </div>
                                {errors.accept && (
                                    <span className="text-red-500 italic text-xs">You must agree to continue...</span>
                                )}
                            </>
                        )}
                        <div className="mt-5 flex gap-3 items-center flex-wrap">
                            <p className="text-md whitespace-nowrap">Enter BTC withdrawal address:</p>
                            <input
                                {...register("walletAddress", { required: true })}
                                className={
                                    "w-full text-white pl-6 py-2 bg-slateblue rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none" +
                                    (errors.walletAddress
                                        ? " border-red-500 border-2 text-red-600 focus:border-red-500 focus:ring-red-500"
                                        : "")
                                }
                                type="text"
                            />
                            {errors.walletAddress && (
                                <span className="text-red-500 italic text-xs">This field is required...</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button
                        type="button"
                        className="relative justify-self-center items-center px-6 py-2 border border-transparent shadow-sm font-medium rounded-full text-white bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-800 uppercase text-center"
                        onClick={handleClose}
                    >
                        Go back
                    </button>
                    <button
                        type="submit"
                        className="relative justify-self-center items-center px-6 py-2 border border-transparent shadow-sm font-medium rounded-full text-darkblue bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase disabled:border-[#707E96] disabled:text-[#1B2535] disabled:bg-[#707E96]"
                    >
                        Confirm
                    </button>
                </div>
                <div className="text-xs pt-4 text-center italic">
                    Withdrawals will be processed within 7 to 10 days.
                </div>
            </form>
        </PopupBase>
    );
};

export default WithdrawPopup;
