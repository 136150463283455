import { FC } from "react";
import { Textfit } from "react-textfit";

interface IconValueProps {
    className?: string;
    imgSrc: string;
    imgAlt: string;
    text?: string | null;
    unit?: string;
    title: string;
}

const IconValue: FC<IconValueProps> = (props) => {
    return (
        <div
            className={
                "border-x-2 border-t-2 border-[#222D3F] rounded text-center relative w-full md:w-26 max-w-[25rem] justify-self-center px-4 py-2" +
                (props.className ? `${props.className}` : "")
            }
        >
            <img
                className="absolute top-0 left-0 right-0 mx-auto -translate-y-[60%] h-24 lg:h-20 z-20"
                src={props.imgSrc}
                alt={props.imgAlt}
            />
            <div className="absolute top-[-20px] left-0 right-0 mx-auto shadow-[0_17px_6px_0px_#ffd918] h-1 rounded-[50%] w-3/4 z-10"></div>
            <div className="text-primary md:text-lg lg:text-base mt-4">
                    {props.title}
            </div>
            {props.text && (
                <div className="text-white">
                    <Textfit mode="single" max={40}>
                        {props.unit && props.unit}
                        {props.text}
                    </Textfit>
                </div>
            )}
        </div>
    );
};

export default IconValue;
