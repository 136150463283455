import moment from "moment";
import React from "react";
import { Area, AreaChart, CartesianGrid, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Portfolio } from "../../models/models";

interface StackedAreaChartProps {
    data: Portfolio[];
}

function StackedAreaChart(props: StackedAreaChartProps) {
    let data = [...props.data].reverse().map((p) => ({
        date: p.date,
        pv: p.stakedBtcUsd,
        uv: p.stakedLpUsd,
    }));

    //shows a flat graph if there is only 1 result
    if (data.length === 1) {
        data.push(data[0]);
    }
    const dateFormatter = (date: string) => {
        if (!date || date === "auto") return "";
        return moment(date).format("DD-MMM");
    };

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            const pay = payload[0]?.payload;
            return (
                <div className="text-sm">
                    <div>{pay.date}</div>
                    <div>
                        YBBTC: $
                        {pay.pv.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })}
                    </div>
                    <div>
                        BTC: $
                        {pay.uv.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })}
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="w-full h-full relative">
            <ResponsiveContainer width="99%" height="100%" className="min-h-[15rem]">
                <AreaChart
                    data={data}
                    margin={{
                        top: 30,
                        right: 60,
                        left: 10,
                        bottom: 20,
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#FFD918" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#FFD918" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#D1D1D1" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#D1D1D1" stopOpacity={0} />
                        </linearGradient>
                        <filter id="shadowUv" height="200%">
                            <feGaussianBlur in="SourceAlpha" stdDeviation="7" result="blur" />
                            <feOffset in="blur" dx="0" dy="0" result="offsetBlur" />
                            <feFlood floodColor="#FFD918" floodOpacity="0.5" result="offsetColor" />
                            <feComposite in="offsetColor" in2="offsetBlur" operator="in" result="offsetBlur" />
                            <feMerge>
                                <feMergeNode />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                        <filter id="shadowPv" height="200%">
                            <feGaussianBlur in="SourceAlpha" stdDeviation="7" result="blur" />
                            <feOffset in="blur" dx="0" dy="0" result="offsetBlur" />
                            <feFlood floodColor="#D1D1D1" floodOpacity="0.5" result="offsetColor" />
                            <feComposite in="offsetColor" in2="offsetBlur" operator="in" result="offsetBlur" />
                            <feMerge>
                                <feMergeNode />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                    <CartesianGrid stroke={"#222b3c"} />
                    <Tooltip content={CustomTooltip} />
                    <XAxis
                        dataKey="date"
                        tickMargin={0}
                        height={20}
                        tick={{ fill: "#A8B0BF" }}
                        tickFormatter={dateFormatter}
                    >
                        <Label value="Time" offset={-15} position="insideBottomLeft" fill="#434E65" fontWeight="700" />
                    </XAxis>
                    <YAxis tick={false} width={20}>
                        <Label value="Value" position="insideBottomLeft" fill="#434E65" fontWeight="700" angle={-90} />
                    </YAxis>
                    <Area
                        type="monotone"
                        dataKey="pv"
                        stackId="1"
                        stroke="#D1D1D1"
                        strokeWidth={3}
                        filter="url(#shadowPv)"
                        fill="url(#colorPv)"
                        strokeLinecap="round"
                    ></Area>
                    <Area
                        type="monotone"
                        dataKey="uv"
                        stackId="1"
                        stroke="#FFD918"
                        strokeWidth={5}
                        filter="url(#shadowUv)"
                        fill="url(#colorUv)"
                        strokeLinecap="round"
                    >
                        <LabelList dataKey="pc" position="top" fill="#FFD918" fontWeight="700" offset={20} />
                    </Area>
                </AreaChart>
            </ResponsiveContainer>
            <div className="absolute right-0 top-0 bottom-0 my-auto flex flex-col justify-center gap-10">
                <div className="text-primary text-sm uppercase text-shadow">
                    Your
                    <br />
                    Stakes
                </div>
                <div className="text-white text-sm uppercase">BTC</div>
            </div>
        </div>
    );
}

export default StackedAreaChart;
