import { ActivityType } from "../components/activity/Activity";

export interface Stake {
    userId: string;
    stakeId: string;
    deposit: {
        btcPayInvoiceId: string;
        invoiceUrl: string;
        invoiceAmountBtc: number;
        invoiceAmountBtcUsd: number;
        payments: Payment[];
        invoiceRaisedAt: Date;
        invoicePaidAt: Date;
        invoiceExpiredAt: Date;
        invoiceSettledAt: Date;
    };
    btcStaked: number;
    btcUsdStaked: number;
    lpStaked: number;
    lpUsdUnstaked: number;
    lpCompounded: number;
    lpRewarded: number;
    lpUnstaked: number;
    stakedAt: Date;
    unstakedAt: Date;
    maturesAt: Date;
    status: StakeStatus;
    partitionKey: string;
    id: string;
}

export interface ActivityData {
    btcValue: number;
    usdValue: number;
    date: Date;
    type: ActivityType;
    id: string;
}

export interface Payment {
    transactionId: string;
    value: number;
    fee: number;
    receivedAt: Date;
}

export enum StakeStatus {
    INITIATED = "INITIATED",
    PENDING = "PENDING",
    STAKED = "STAKED",
    UNSTAKED = "UNSTAKED",
    FAILED = "FAILED",
}

export interface TVL {
    "56": {
        "bifi-gov": number;
        "bitbomb-bitbtc-btcb": number;
        "bomb-bshare-wbnb": number;
    };
}

export interface Prices {
    btcUsdt: number;
    bnbUsdt: number;
    bombBtcLpUsdt: number;
}

export interface APY {
    "bomb-bshare-wbnb": number;
    "bitbomb-bitbtc-btcb": number;
}

export interface ApyBreakdown {
    "bomb-bshare-wbnb": {
        vaultApr: number;
        compoundingsPerYear: number;
        beefyPerformanceFee: number;
        vaultApy: number;
        lpFee: number;
        tradingApr: number;
        totalApy: number;
    };
    "bitbomb-bitbtc-btcb": {
        vaultApr: number;
        compoundingsPerYear: number;
        beefyPerformanceFee: number;
        vaultApy: number;
        lpFee: number;
        tradingApr: number;
        totalApy: number;
    };
}

export interface PortfolioTotals {
    totalLpStaked: number;
    totalLpRewarded: number;
    totalLpCompounded: number;
    totalStakes: number;
}

export interface Portfolio {
    date: Date;
    userId: string;
    stakedBtc: number;
    stakedBtcUsd: number;
    stakedLp: number;
    stakedLpUsd: number;
    partitionKey: string;
    id: string;
}
