import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Helmet } from "react-helmet-async";
import Activity, { ActivityType } from "../components/activity/Activity";
import Navbar from "../components/navbar/Navbar";
import StackedAreaChart from "../components/stackedAreaChart/StackedAreaChart";
import StakeInput from "../components/stakeInput/StakeInput";
import StakingHistory from "../components/stakingHistory/StakingHistory";
import BombCoinFront from "../images/coin-logobomb-front.png";
import { ReactComponent as TVL } from "../images/bomb-TVL-icon.svg";
import { ReactComponent as Stakes } from "../images/bomb-STAKES-icon.svg";
import { ReactComponent as Rewards } from "../images/bomb-REWARDS-icon.svg";
import HeaderBG from "../images/header-bg.jpg";
import Bomb from "../images/bomb logo 2-3D-2-front.png";
import { useGlobalContext } from "../providers/GlobalContext";
import { ActivityData, StakeStatus } from "../models/models";
import { toast } from "react-toastify";
import { Textfit } from "react-textfit";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";

function DashboardPage() {
    const { isAuthenticated, isLoading, loginWithRedirect, error, user } = useAuth0();
    const {
        getStakes,
        getTVL,
        getPortfolio,
        getPortfolioTotals,
        getPrices,
        getAPYBreakdown,
        apyBreakdown,
        stakes,
        tvl,
        portfolio,
        prices,
        appSettings,
        portfolioTotals,
    } = useGlobalContext();

    // const [isEmailVerified, setIsEmailVerified] = useState(false);

    useEffect(() => {
        if (!isLoading && !isAuthenticated && !error) {
            loginWithRedirect();
        } else if (
            (!isLoading &&
                !isAuthenticated &&
                error &&
                error?.message === "Please verify your email before logging in.") ||
            (!isLoading && isAuthenticated && !user?.email_verified)
        ) {
            toast("You must verify your email to use this dashboard!", {
                autoClose: false,
                type: "warning",
                theme: "colored",
                containerId: "nav",
                position: "top-center",
            });
        } else {
            // setIsEmailVerified(true);
        }
    }, [isAuthenticated, loginWithRedirect, isLoading, error, user?.email_verified]);

    const [stakeAmount, setStakeAmount] = useState<number>(0);

    useEffect(() => {
        if (appSettings?.Settings.MinimumInvoiceAmount) {
            setStakeAmount(appSettings?.Settings.MinimumInvoiceAmount);
        }
    }, [appSettings?.Settings.MinimumInvoiceAmount]);

    useEffect(() => {
        getStakes();
        getTVL();
        getPortfolio();
        getPortfolioTotals();
        getPrices();
        getAPYBreakdown();
    }, [getStakes, getTVL, getPortfolio, getPortfolioTotals, getPrices, getAPYBreakdown]);

    const totalLpStaked = stakes
        .filter((x) => x.status === StakeStatus.STAKED)
        .map((x) => x.lpStaked + x.lpRewarded + x.lpCompounded)
        .reduce((a, b) => {
            return a + b;
        }, 0);

    const originalAndCompoundedLpStaked = stakes
        .filter((x) => x.status === StakeStatus.STAKED)
        .map((x) => x.lpStaked + x.lpCompounded)
        .reduce((a, b) => {
            return a + b;
        }, 0);

    const totalLpRewarded = stakes
        .filter((x) => x.status === StakeStatus.STAKED)
        .map((x) => x.lpRewarded + x.lpCompounded)
        .reduce((a, b) => {
            return a + b;
        }, 0);

    const currentLpRewarded = stakes
        .filter((x) => x.status === StakeStatus.STAKED)
        .map((x) => x.lpRewarded)
        .reduce((a, b) => {
            return a + b;
        }, 0);

    const currentGains = stakes
        .filter((x) => x.status === StakeStatus.STAKED)
        .map((x) => (x.lpRewarded + x.lpCompounded) / (x.lpStaked + x.lpRewarded + x.lpCompounded))
        .reduce((a, b) => {
            return a + b;
        }, 0);

    const sortedPortfolios = portfolio.sort((a, b) => {
        return moment(a.date).isSameOrBefore(b.date) ? 1 : -1;
    });

    const currentPortfolio = sortedPortfolios[0];
    const firstPortfolioOfTheMonth = sortedPortfolios
        .filter(
            (x) =>
                moment(x.date).month() === moment(currentPortfolio.date).month() &&
                moment(x.date).year() === moment(currentPortfolio.date).year()
        )
        ?.slice(-1)[0];
    return (
        <>
            <Helmet>
                <title>Dashboard - Bomb Crypto</title>
            </Helmet>
            <div
                className="flex-1 min-h-screenbg-no-repeat bg-scroll bg-cover bg-center"
                style={{ backgroundImage: `url('${HeaderBG}')` }}
            >
                <Navbar title={"BTC Dashboard"} />
                {/* <div className="hidden absolute top-0 bottom-0 right-0 my-auto z-10 max-w-[3rem] overflow-hidden md:flex justify-center flex-col">
                     <div className="" style={{ "width": "2rem", "height": "3rem", "position": "absolute", "transform": "skew(\n294deg\n, \n1deg\n)", "right": "0", "cursor": "pointer", "top": "-25%", "margin": "auto", "bottom": "0" }}></div>
                    <div className="" style={{ "width": "3rem", "height": "11.5rem", "position": "absolute", "right": "0px", "cursor": "pointer", "top": "0", "margin": "auto", "bottom": "0px" }}></div>
                    <div className="" style={{ "width": "2rem", "height": "3rem", "position": "absolute", "transform": "skew(\n245deg\n, \n1deg\n)", "right": "0", "cursor": "pointer", "top": "27%", "margin": "auto", "bottom": "0" }}></div>
                    <img className="translate-x-2" src={Calc} alt="Calculator" />
                </div> */}
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white">
                    <div className="text-white mb-6 mt-2">
                        <div className="text-2xl">Welcome back!</div>
                        {currentPortfolio && firstPortfolioOfTheMonth && (
                            <div className="">
                                Your investments have increased by{" "}
                                <span className="text-primary font-medium">
                                    {
                                        <span>
                                            {(
                                                ((currentPortfolio.stakedLpUsd - firstPortfolioOfTheMonth.stakedLpUsd) /
                                                    firstPortfolioOfTheMonth.stakedLpUsd) *
                                                100
                                            ).toLocaleString(undefined, {
                                                maximumFractionDigits: 2,
                                            })}
                                            %
                                        </span>
                                    }{" "}
                                    {prices && (
                                        <span>
                                            ($
                                            {(
                                                currentPortfolio.stakedLpUsd - firstPortfolioOfTheMonth.stakedLpUsd
                                            ).toLocaleString(undefined, {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                            })}
                                            )
                                        </span>
                                    )}
                                </span>{" "}
                                over the month of {moment(currentPortfolio.date).format("MMMM")}.
                            </div>
                        )}
                    </div>
                    <div className="mb-4">
                        <div className="grid gap-x-4 gap-y-4 lg:grid-cols-4 ">
                            <div className="lg:row-span-2 col-span-3 sm:col-span-1 bg-gradient-to-t from-slate-900/80 via-[#1F2428] to-[#615922] backdrop-blur-sm p-4 rounded-lg border-[3px] border-primary shadow-glow shadow-primary">
                                <div className="flex justify-center h-48 -mb-8">
                                    <img className="h-full -mt-4" src={Bomb} alt="bomb money" />
                                </div>
                                <div className="text-center mb-4 grid grid-cols-2 overflow-hidden w-100 gap-2 justify-evenly">
                                    <div>
                                        <div className="text-white font-bold text-md">Live Price</div>
                                        <div className="text-white text-2xl font-medium">
                                            <Textfit mode="multi" max={24}>
                                                1
                                            </Textfit>
                                            <span className="text-sm">YB</span>
                                            BTC
                                        </div>
                                        <div className="text-slate-500 text-md">
                                            {prices &&
                                                prices.bombBtcLpUsdt.toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                })}{" "}
                                            USD
                                        </div>
                                    </div>
                                    <div>
                                        {/* todo: total stakes or btc deposited */}
                                        <div className="text-white font-bold text-md">Your Stake</div>
                                        <div className="text-white text-2xl font-medium">
                                            <Textfit mode="multi" max={24}>
                                                {originalAndCompoundedLpStaked?.toLocaleString(undefined, {
                                                    maximumFractionDigits: 6,
                                                })}
                                            </Textfit>
                                            <span className="text-sm">YB</span>
                                            BTC
                                        </div>
                                        <div className="text-slate-500 text-md">
                                            {prices &&
                                                (originalAndCompoundedLpStaked * prices.bombBtcLpUsdt).toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                })}{" "}
                                            USD
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-4">
                                    <div className="text-white font-bold text-md">
                                        Your Rewards{" "}
                                        <a
                                            href="https://chat.bomb.app/hc/bomb-btc-help/en/bomb-btc-single-stake-in-depth/9"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="h-6 w-6 inline-flex"
                                        >
                                            <QuestionMarkCircleIcon className="stroke-primary" />
                                        </a>
                                    </div>
                                    <div className="text-primary text-2xl font-medium">
                                        {currentLpRewarded?.toLocaleString(undefined, {
                                            maximumFractionDigits: 6,
                                        })}{" "}
                                        <span className="text-sm">YB</span>BTC
                                    </div>
                                    <div className="text-slate-500 text-md">
                                        {prices &&
                                            (currentLpRewarded * prices.bombBtcLpUsdt).toLocaleString(undefined, {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                            })}{" "}
                                        USD
                                    </div>
                                </div>
                                {/* <StakeInput btnText="Stake" setStakeAmount={setStakeAmount} stakeAmount={stakeAmount} /> */}
                            </div>
                            <div className="lg:col-span-3 sm:col-span-2 col-span-3">
                                <div className="grid gap-x-4 gap-y-4 lg:grid-cols-3 grid-cols-1">
                                    <div className="col-span-3 lg:col-span-1 bg-gradient-to-t from-slate-900/80 via-[#181E29] to-[#282C26] backdrop-blur-sm px-6 py-6 rounded-lg border border-[#293b50]">
                                        <div className="flex flex-row justify-between items-center">
                                            <div className="bg-[#6E6535] min-w-fit min-h-fit rounded-full p-2 border border-[#A79011]">
                                                <TVL className="h-8 w-8" />
                                            </div>
                                            <div>
                                                <div className="text-primary text-lg font-bold pl-3 text-right">
                                                    Total Value Locked
                                                </div>

                                                <div className="text-white text-2xl font-medium pl-3 text-right">
                                                    $
                                                    {tvl &&
                                                        tvl?.[56]["bitbomb-bitbtc-btcb"].toLocaleString(undefined, {
                                                            maximumFractionDigits: 0,
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-3 lg:col-span-1 bg-gradient-to-t from-slate-900/80 via-[#181E29] to-[#282C26] backdrop-blur-sm px-6 py-6 rounded-lg border border-[#293b50]">
                                        <div className="flex flex-row justify-between items-center">
                                            <div className="bg-[#6E6535] min-w-fit min-h-fit rounded-full p-2 border border-[#A79011]">
                                                <Rewards className="h-8 w-8" />
                                            </div>
                                            <div>
                                                <div className="text-primary text-lg font-bold pl-3 text-right text-right">
                                                    Total Rewards Paid
                                                </div>

                                                <div className="text-white text-2xl font-medium pl-3 text-right text-right">
                                                    $
                                                    {portfolioTotals &&
                                                        prices &&
                                                        (
                                                            (portfolioTotals?.totalLpRewarded +
                                                                portfolioTotals?.totalLpCompounded) *
                                                            prices?.bombBtcLpUsdt
                                                        )?.toLocaleString(undefined, {
                                                            maximumFractionDigits: 0,
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-3 lg:col-span-1 bg-gradient-to-t from-slate-900/80 via-[#181E29] to-[#282C26] backdrop-blur-sm px-6 py-6 rounded-lg border border-[#293b50]">
                                        <div className="flex flex-row justify-between items-center">
                                            <div className="bg-[#6E6535] min-w-fit min-h-fit rounded-full p-2 border border-[#A79011]">
                                                <Stakes className="h-8 w-8" />
                                            </div>
                                            <div>
                                                <div className="text-primary text-lg font-bold pl-3 text-right">
                                                    Annual APR
                                                </div>
                                                <div className="text-white text-2xl font-medium pl-3 text-right">
                                                    {apyBreakdown && (
                                                        <div>
                                                            {(apyBreakdown?.["bitbomb-bitbtc-btcb"].vaultApr * 50) //todo: update with own api
                                                                .toLocaleString(undefined, {
                                                                    maximumFractionDigits: 2,
                                                                })}
                                                            %
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <div className="grid gap-x-4 gap-y-4 md:grid-cols-2">
                                    <div className="col-span-1 bg-slate-900/80 backdrop-blur-sm p-4 rounded-lg border border-[#293b50]">
                                        <StackedAreaChart data={sortedPortfolios} />
                                    </div>
                                    <div className="col-span-1 bg-slate-900/80 backdrop-blur-sm px-8 py-4 rounded-lg flex border border-[#293b50]">
                                        <div className="flex flex-col justify-center gap-4">
                                            <div className="flex gap-4 flex-row">
                                                <div className="bg-primary h-full w-2 self-center rounded-full shadow-glow-md shadow-primary"></div>
                                                <div className="">
                                                    <div className="text-white text-xl font-bold">Your Rewards</div>
                                                    <div className="text-white text-lg font-bold">
                                                        {currentLpRewarded?.toLocaleString(undefined, {
                                                            maximumFractionDigits: 6,
                                                        })}{" "}
                                                        <span className="text-xs">YB</span>
                                                        BTC
                                                    </div>
                                                    <div className="text-slate-500 text-base">
                                                        {prices &&
                                                            (currentLpRewarded * prices.bombBtcLpUsdt).toLocaleString(
                                                                undefined,
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                    minimumFractionDigits: 2,
                                                                }
                                                            )}{" "}
                                                        USD
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex gap-4 flex-row">
                                                <div className="bg-[#344663] h-full w-2 self-center rounded-full"></div>
                                                <div className="">
                                                    <div className="text-white text-xl font-bold">Your Stake</div>
                                                    <div className="text-white text-lg font-bold">
                                                        {totalLpStaked?.toLocaleString(undefined, {
                                                            maximumFractionDigits: 6,
                                                        })}{" "}
                                                        <span className="text-xs">YB</span>
                                                        BTC
                                                    </div>
                                                    <div className="text-slate-500 text-base">
                                                        {prices &&
                                                            (totalLpStaked * prices.bombBtcLpUsdt).toLocaleString(
                                                                undefined,
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                    minimumFractionDigits: 2,
                                                                }
                                                            )}{" "}
                                                        USD
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 rounded-full self-center flex justify-center relative">
                                            <div className="w-[90%] max-w-[16rem] self-center ">
                                                <CircularProgressbarWithChildren
                                                    value={currentGains * 100}
                                                    backgroundPadding={10}
                                                    strokeWidth={5}
                                                    background={true}
                                                    text={`+${(
                                                        currentGains *  100
                                                    ).toLocaleString(undefined, {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 0,
                                                    })}
                                                    %`}
                                                    styles={{
                                                        trail: {
                                                            stroke: "#181e29",
                                                            transform: "rotate(0.25turn)",
                                                            transformOrigin: "center center",
                                                            filter: "drop-shadow(0px 0px 0.5px #42484B)",
                                                        },
                                                        text: {
                                                            transform: "translate(30%, -45%)",
                                                            transformOrigin: "center",
                                                            fontSize: "0.5rem",
                                                            fontWeight: "500",
                                                            fill: "#FFD658",
                                                        },
                                                        path: {
                                                            stroke: "#FFD658",
                                                            filter: "drop-shadow(#FFD658 0px 0px 5px)",
                                                        },
                                                        background: {
                                                            fill: "transparent",
                                                            stroke: "transparent",
                                                        },
                                                    }}
                                                >
                                                    <img className="p-[25%]" src={BombCoinFront} alt="BOMB Coin" />
                                                </CircularProgressbarWithChildren>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="overflow-auto min-h-[15rem] max-h-[20rem] col-span-3 lg:col-span-1 bg-slate-900/80 backdrop-blur-sm rounded-lg border border-[#293b50] scrollbar scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-slate-700">
                                <div className="px-3 pb-2 pt-2">
                                    <div className="text-white text-xl font-medium">Recent Activities</div>
                                    <div></div>
                                </div>
                                <div className="px-3 pb-4 grid gap-2">
                                    {prices &&
                                        stakes
                                            .reduce((a, b) => {
                                                //duplicate the entry if it is unstaked
                                                if (
                                                    b.status === StakeStatus.STAKED ||
                                                    b.status === StakeStatus.UNSTAKED
                                                ) {
                                                    if (
                                                        new Date(b.stakedAt).toISOString() !==
                                                        new Date("0001-01-01T00:00:00+00:00").toISOString()
                                                    ) {
                                                        a.push({
                                                            date: b.stakedAt,
                                                            btcValue: b.lpStaked,
                                                            usdValue: b.btcUsdStaked,
                                                            type: ActivityType.Stake,
                                                            id: b.id + StakeStatus.STAKED,
                                                        });
                                                    }
                                                    if (
                                                        new Date(b.unstakedAt).toISOString() !==
                                                        new Date("0001-01-01T00:00:00+00:00").toISOString()
                                                    ) {
                                                        a.push({
                                                            date: b.unstakedAt,
                                                            btcValue: b.lpUnstaked,
                                                            usdValue: b.lpUsdUnstaked,
                                                            type: ActivityType.Withdrawal,
                                                            id: b.id + StakeStatus.UNSTAKED,
                                                        });
                                                    }
                                                }
                                                return a;
                                            }, [] as ActivityData[])
                                            .sort((a, b) => {
                                                return moment(a.date).isSameOrBefore(b.date) ? 1 : -1;
                                            })
                                            .map((stake) => {
                                                return (
                                                    <Activity
                                                        key={stake.id}
                                                        type={stake.type}
                                                        stakedAt={stake.date}
                                                        btcAmount={stake.btcValue ?? 0}
                                                        usdAmount={stake.usdValue ?? 0}
                                                    />
                                                );
                                            })}
                                </div>
                            </div>
                            <div className="min-h-[15rem] max-h-[20rem] col-span-3 bg-slate-900/80 backdrop-blur-sm px-4 pt-2 pb-4 rounded-lg border border-[#293b50] overflow-auto scrollbar scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-slate-700">
                                <div className="gap-2 grid whitespace-nowrap">
                                    <div className="flex gap-4 items-center">
                                        <div className="text-white text-xl font-medium w-[19%]">Staking History</div>
                                        <div className="text-slate-500 text-center w-[10%]">Lock Value</div>
                                        <div className="text-slate-500 text-center w-[11%]">Lock Type</div>
                                        <div className="text-slate-500 text-center w-[14%]">Timer</div>
                                        <div className="text-slate-500 text-center w-[12%]">Rewards</div>
                                        <div className="text-slate-500 text-center w-[20%] mr-4">Actions</div>
                                    </div>
                                    {stakes
                                        .filter(
                                            (x) =>
                                                !moment(x.stakedAt).add(1, "hour").isSameOrBefore() ||
                                                x.status !== StakeStatus.FAILED
                                        )
                                        .sort((a, b) => {
                                            return moment(a.stakedAt).isSameOrBefore(b.stakedAt) ? 1 : -1;
                                        })
                                        .map((stake) => {
                                            return <StakingHistory key={stake.id} stake={stake} prices={prices} />;
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default DashboardPage;
