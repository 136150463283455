import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import PopupBase from "../popup/PopupBase";

interface EmailPopupProps {
    className?: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    recaptchaClientKey: string;
    confirmCallback: (email: string, recaptcha: string) => Promise<boolean>;
}

const EmailPopup: FC<EmailPopupProps> = (props) => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>();
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setEmail("");
        setLoading(false);
        setRecaptchaResponse(null);
        setSubmitted(false);
    }, [props.open]);

    return (
        <PopupBase onClose={() => props.setOpen(false)} open={props.open} className={props.className}>
            <div className="">
                <div className="text-white">
                    <Dialog.Title as="h3" className="">
                        <div>
                            <div className="text-center text-lg mb-2">
                                How can we offer the best Bitcoin APY?
                                <br />
                                What makes it so safe?
                            </div>
                        </div>
                    </Dialog.Title>
                    <div className="my-4"></div>
                </div>
            </div>
            <label htmlFor="email-subscribe" className="">
                Enter your email below to learn more!
            </label>
            <input
                id="email-subscribe"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(x) => setEmail(x.target.value)}
                className="mt-1 border border-slate-300 w-full text-white pl-6 py-2 bg-slateblue rounded-full shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500  overflow-auto scrollbar scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-slate-700"
            />
            {!email && submitted && (
                <div className="text-xs text-red-500 italic">You must enter your email to subscribe.</div>
            )}
            <div className="mt-6">
                <ReCAPTCHA
                    sitekey={props.recaptchaClientKey}
                    onChange={(x) => {
                        setRecaptchaResponse(x);
                    }}
                />
            </div>

            {!recaptchaResponse && submitted && (
                <div className="text-xs text-red-500 italic">You must solve the recaptcha to subscribe.</div>
            )}
            <div className="mt-5 sm:mt-6">
                <button
                    onClick={() => {
                        setSubmitted(true);

                        if (!recaptchaResponse || !email) return;
                        setLoading(true);
                        props
                            .confirmCallback(email, recaptchaResponse ?? "")
                            .then((resp) => {
                                if (resp) {
                                    toast("Email signed up successfully...", {
                                        type: "success",
                                        theme: "colored",
                                    });
                                } else {
                                    toast("Failed to sign up email, please try again...", {
                                        type: "error",
                                        theme: "colored",
                                    });
                                }
                                props.setOpen(false);
                            })
                            .catch((e) => {
                                toast(
                                    "Something went wrong while trying to sign up your email. Please try again later...",
                                    {
                                        type: "error",
                                        theme: "colored",
                                    }
                                );
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    }}
                    disabled={loading}
                    className="relative justify-self-center items-center px-6 py-2 border border-transparent shadow-sm font-medium rounded-full text-darkblue bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase disabled:border-[#707E96] disabled:text-[#1B2535] disabled:bg-[#707E96]"
                >
                    Subscribe
                </button>
            </div>
        </PopupBase>
    );
};

export default EmailPopup;
