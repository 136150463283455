import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import moment from "moment";
import * as React from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AppSettings } from "../App";
import { APY, ApyBreakdown, Portfolio, PortfolioTotals, Prices, Stake, TVL } from "../models/models";
import throttle from "lodash.throttle";
import { toast } from "react-toastify";
const axiosStaking = axios.create();
const axiosStakingPublic = axios.create();
const axiosBomb = axios.create();
const THROTTLE_TIME = 10000;
const THROTTLE_OPTIONS = { leading: true, trailing: false };
const ACCESS_TOKEN_STORAGE_NAME = "access_token";

export interface GlobalContextProps {
    appSettings: AppSettings;
}

//set type here
export interface GlobalContextInterface {
    createStake: (amount: number) => void;
    withdrawStake: (stakeId: string, walletAddress: string) => void;
    getStakes: () => void;
    getPortfolio: () => void;
    getPortfolioTotals: () => void;
    getTVL: () => void;
    getAPY: () => void;
    getAPYBreakdown: () => void;
    getPrices: () => void;
    clearStoredAccessToken: () => void;
    subscribeEmail: (email: string, recaptcha: string) => Promise<boolean>;
    stakes: Stake[];
    tvl: TVL | null;
    apyBreakdown: ApyBreakdown | null;
    apy: APY | null;
    prices: Prices | null;
    portfolio: Portfolio[];
    portfolioTotals: PortfolioTotals | null;
    appSettings?: AppSettings;
}

//set default value here
export const GlobalContext = createContext<GlobalContextInterface>({
    createStake: () => {
        console.error("createStake - Not yet implemented...");
    },
    withdrawStake: () => {
        console.error("withdrawStake - Not yet implemented...");
    },
    getStakes: () => {
        console.error("getStakes - Not yet implemented...");
    },
    getPortfolio: () => {
        console.error("getPortfolio - Not yet implemented...");
    },
    getPortfolioTotals: () => {
        console.error("getPortfolio - Not yet implemented...");
    },
    getTVL: () => {
        console.error("getTVL - Not yet implemented...");
    },
    getAPY: () => {
        console.error("getAPY - Not yet implemented...");
    },
    getAPYBreakdown: () => {
        console.error("getAPYBreakdown - Not yet implemented...");
    },
    getPrices: () => {
        console.error("getPrices - Not yet implemented...");
    },
    clearStoredAccessToken: () => {
        console.error("clearStoredAccessToken - Not yet implemented...");
    },
    subscribeEmail: () =>
        new Promise(() => {
            console.error("subscribeEmail - Not yet implemented...");
        }),
    stakes: [],
    tvl: null,
    apyBreakdown: null,
    apy: null,
    prices: null,
    portfolio: [],
    portfolioTotals: null,
    appSettings: undefined,
});

export const GlobalProvider: React.FC<GlobalContextProps> = (props) => {
    const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();
    const [visibilityState, setVisibilityState] = useState("visible");
    const [stakes, setStakes] = useState<Stake[]>([]);
    const [tvl, setTvl] = useState<TVL | null>(null);
    const [apyBreakdown, setApyBreakdown] = useState<ApyBreakdown | null>(null);
    const [apy, setApy] = useState<APY | null>(null);
    const [prices, setPrices] = useState<Prices | null>(null);
    const [portfolio, setPortfolio] = useState<Portfolio[]>([]);
    const [portfolioTotals, setPortfolioTotals] = useState<PortfolioTotals | null>(null);

    //sets up default axios settings
    axiosStakingPublic.defaults.baseURL = props.appSettings.Api.StakingApi;
    axiosStaking.defaults.baseURL = props.appSettings.Api.StakingApi;
    axiosBomb.defaults.baseURL = props.appSettings.Api.BombApi;

    const getStakes = useMemo(
        () =>
            throttle(
                () =>
                    axiosStaking.get(`/stakes`).then((resp) => {
                        setStakes(resp.data);
                    }),
                THROTTLE_TIME,
                THROTTLE_OPTIONS
            ),
        []
    );

    const createStake = useCallback(
        (amount) => {
            axiosStaking.post(`/stakes`, { amount: amount }).then((resp) => {
                const stake: Stake = resp.data;
                const url = stake.deposit.invoiceUrl;
                getStakes();
                window.open(url, "_blank");
            });
        },
        [getStakes]
    );

    const withdrawStake = useCallback(
        (stakeId: string, walletAddress: string) => {
            axiosStaking
                .post(`/stakes/${stakeId}/withdraw`, { walletAddress: walletAddress })
                .then((resp) => {
                    getStakes();
                })
                .catch(() => {
                    toast("Withdrawal request failed...", {
                        type: "error",
                        theme: "colored",
                    });
                });
        },
        [getStakes]
    );

    const getPortfolio = useMemo(
        () =>
            throttle(
                () =>
                    axiosStaking.get(`/portfolio`, {}).then((resp) => {
                        const ret: Portfolio[] = resp.data;
                        setPortfolio(ret);
                    }),
                THROTTLE_TIME,
                THROTTLE_OPTIONS
            ),
        []
    );

    const getPortfolioTotals = useMemo(
        () =>
            throttle(
                () =>
                    axiosStakingPublic.get(`/portfolio/totals`, {}).then((resp) => {
                        const ret: PortfolioTotals = resp.data;
                        setPortfolioTotals(ret);
                    }),
                THROTTLE_TIME,
                THROTTLE_OPTIONS
            ),
        []
    );

    const getPrices = useMemo(
        () =>
            throttle(
                () =>
                    axiosStakingPublic.get(`/price/all`, {}).then((resp) => {
                        const ret: Prices = resp.data;
                        setPrices(ret);
                    }),
                THROTTLE_TIME,
                THROTTLE_OPTIONS
            ),
        []
    );

    const getTVL = useMemo(
        () =>
            throttle(
                () =>
                    axiosBomb.get(`/tvl`, {}).then((resp) => {
                        const ret: TVL = resp.data;
                        setTvl(ret);
                    }),
                THROTTLE_TIME,
                THROTTLE_OPTIONS
            ),
        []
    );

    const getAPY = useMemo(
        () =>
            throttle(
                () =>
                    axiosBomb.get(`/apy`, {}).then((resp) => {
                        const ret: APY = resp.data;
                        setApy(ret);
                    }),
                THROTTLE_TIME,
                THROTTLE_OPTIONS
            ),
        []
    );
    const getAPYBreakdown = useMemo(
        () =>
            throttle(
                () =>
                    axiosBomb.get(`/apy/breakdown`, {}).then((resp) => {
                        const ret: ApyBreakdown = resp.data;
                        setApyBreakdown(ret);
                    }),
                THROTTLE_TIME,
                THROTTLE_OPTIONS
            ),
        []
    );

    const clearStoredAccessToken = useCallback(() => {
        localStorage.removeItem(ACCESS_TOKEN_STORAGE_NAME);
    }, []);

    const subscribeEmail = useCallback((email: string, recaptcha: string) => {
        return axiosStakingPublic.post(`/email/subscribe`, { email: email, recaptchaToken: recaptcha }).then((resp) => {
            const wasSuccessful: boolean = resp.data;
            return wasSuccessful;
        });
    }, []);

    //private functions to run on timer when authenticated
    const runPrivateAPICalls = useCallback(() => {
        if (isAuthenticated) {
            getStakes();
            getPortfolio();
        }
    }, [getStakes, getPortfolio, isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            const timeoutID = window.setInterval(() => {
                runPrivateAPICalls();
            }, 30000);
            if (visibilityState !== "visible") {
                window.clearTimeout(timeoutID);
            } else {
                runPrivateAPICalls();
            }
            return () => window.clearTimeout(timeoutID);
        }
    }, [runPrivateAPICalls, visibilityState, isAuthenticated]);

    //public functions to run on timer
    const runPublicAPICalls = useCallback(() => {
        getTVL();
        getAPYBreakdown();
        getPrices();
        getPortfolioTotals();
    }, [getTVL, getAPYBreakdown, getPrices, getPortfolioTotals]);

    useEffect(() => {
        const timeoutID = window.setInterval(() => {
            runPublicAPICalls();
        }, 30000);
        if (visibilityState !== "visible") {
            window.clearTimeout(timeoutID);
        } else {
            runPublicAPICalls();
        }
        return () => window.clearTimeout(timeoutID);
    }, [runPublicAPICalls, visibilityState]);

    //handles visibility changes
    const setVisibilityStatus = useCallback(
        (e: any) => {
            setVisibilityState(e.target.visibilityState);
        },
        [setVisibilityState]
    );
    useEffect(() => {
        document.addEventListener("visibilitychange", setVisibilityStatus, false);
        return () => document.removeEventListener("visibilitychange", setVisibilityStatus);
    });

    //set up interceptor for axios requests
    axiosStaking.interceptors.request.use(
        (config: any) => {
            //check for valid local cache for access token
            const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);
            if (accessToken) {
                try {
                    const [, payload] = accessToken.split(".");
                    const { exp: expires } = JSON.parse(window.atob(payload));
                    if (typeof expires === "number") {
                        if (moment().isBefore(new Date(expires * 1000))) {
                            config.headers["Authorization"] = `Bearer ${accessToken}`;

                            //ensures that the token stays fresh
                            getAccessTokenSilently().then((accessToken) => {
                                //put session in storage
                                localStorage.setItem(ACCESS_TOKEN_STORAGE_NAME, accessToken);
                            });
                            return config;
                        }
                    }
                } catch (error) {
                    //something went wrong, so remove the stored token
                    localStorage.removeItem(ACCESS_TOKEN_STORAGE_NAME);
                }
            }
            //get cached or new access token
            return getAccessTokenSilently()
                .then((accessToken) => {
                    //put session in storage
                    localStorage.setItem(ACCESS_TOKEN_STORAGE_NAME, accessToken);
                    //add header with refreshed token
                    config.headers["Authorization"] = `Bearer ${accessToken}`;
                    return config;
                })
                .catch((err) => {
                    //fallback to interactive login if silent auth fails
                    if (err.message !== "Please verify your email before logging in.") {
                        loginWithRedirect();
                    }
                    return config;
                });
        },
        function (err) {
            return Promise.reject(err);
        }
    );

    return (
        //pass state here
        <GlobalContext.Provider
            value={{
                createStake: createStake,
                withdrawStake: withdrawStake,
                getStakes: getStakes,
                getPortfolio: getPortfolio,
                getPortfolioTotals: getPortfolioTotals,
                getTVL: getTVL,
                getAPY: getAPY,
                getAPYBreakdown: getAPYBreakdown,
                getPrices: getPrices,
                clearStoredAccessToken: clearStoredAccessToken,
                subscribeEmail: subscribeEmail,
                stakes: stakes,
                tvl: tvl,
                apyBreakdown: apyBreakdown,
                apy: apy,
                prices: prices,
                portfolio: portfolio,
                portfolioTotals: portfolioTotals,
                appSettings: props.appSettings,
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};

export const useGlobalContext = () => useContext(GlobalContext);
