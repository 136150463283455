import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import Navbar from "../components/navbar/Navbar";
import Scroller from "../components/scroller/Scroller";
import StakeInput from "../components/stakeInput/StakeInput";
import { BombFarmWebsiteName, DashboardPageName } from "../Constants";
import BitCoinFront from "../images/BCTB-icon-3D-front.png";
import Logo from "../images/bomb logo 1.png";
import CenterBomb from "../images/bomb logo 2-3D-2-front.png";
import Computer from "../images/bomb-mac.png";
import BombMaximiseBG from "../images/bomb-maximise-bg.png";
import BombCoinFront from "../images/coin-logobomb-front.png";
import CtaBanner from "../images/cta-banner.png";
import Globe from "../images/globe.png";
import Graph from "../images/graph.png";
import HeaderBG from "../images/hero-bg.png";
import Lock from "../images/lock.png";
import People from "../images/people.png";
import Swap from "../images/swap.png";
import BombLogoSide from "../images/coin-logobomb-side1.png";
import BTCBLogoSide from "../images/BCTB-icon-3D-side3.png";
import SocialTwitter from "../images/twitter.png";
import SocialTelegram from "../images/telegram.png";
import SocialDiscord from "../images/discord.png";
import SocialMedium from "../images/medium.png";
import { useGlobalContext } from "../providers/GlobalContext";
import Calculation from "../components/calc/Calculation";
import EmailPopup from "../components/email/EmailPopup";
import ReactPlayer from "react-player";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import IconValue from "../components/card/IconValue";

function HomePage() {
    const {
        getAPYBreakdown,
        getTVL,
        getPortfolioTotals,
        getPrices,
        apyBreakdown,
        tvl,
        portfolioTotals,
        prices,
        appSettings,
        subscribeEmail,
    } = useGlobalContext();

    useEffect(() => {
        getAPYBreakdown();
        getTVL();
        getPortfolioTotals();
        getPrices();
    }, [getAPYBreakdown, getTVL, getPortfolioTotals, getPrices]);

    const apr = apyBreakdown && apyBreakdown["bitbomb-bitbtc-btcb"].vaultApr * 50;
    const formattedApr = apr?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
    });
    const minimumInvoiceAmount = appSettings?.Settings.MinimumInvoiceAmount;

    const [openEmailPopup, setOpenEmailPopup] = useState(false);
    const [stakeAmount, setStakeAmount] = useState<number>(0);
    const [calcValue, setCalcValue] = useState<number>();
    const [loadedStakeAmount, setLoadedStakeAmount] = useState(true);

    //populates the minimumInvoice amount on first load
    useEffect(() => {
        if (minimumInvoiceAmount && loadedStakeAmount) {
            setStakeAmount(minimumInvoiceAmount);
            setLoadedStakeAmount(false);
        }
    }, [minimumInvoiceAmount, loadedStakeAmount]);

    //updates the btc value
    useEffect(() => {
        if (prices?.btcUsdt && minimumInvoiceAmount && !!stakeAmount) {
            const val = stakeAmount * prices.btcUsdt;
            const str = val.toLocaleString(undefined, { maximumFractionDigits: 2, useGrouping: false });
            setCalcValue(parseFloat(str));
        }
    }, [prices?.btcUsdt, minimumInvoiceAmount, setCalcValue, stakeAmount]);

    return (
        <>
            <Helmet>
                <title>Home - Bitcoin Staking</title>
            </Helmet>
            {appSettings?.Google.Recaptcha.EmailSubscribeKey && (
                <EmailPopup
                    open={openEmailPopup}
                    setOpen={setOpenEmailPopup}
                    recaptchaClientKey={appSettings?.Google.Recaptcha.EmailSubscribeKey}
                    confirmCallback={subscribeEmail}
                />
            )}
            <div className="h-full min-h-screen">
                <div className="relative before:absolute before:rounded-full before:right-0 before:top-0 before:bottom-0 before:shadow-glow-xxl before:shadow-yellow-400 before:m-auto before:h-1 md:before:h-5">
                    <div className="bg-no-repeat bg-right relative" style={{ backgroundImage: `url('${HeaderBG}')` }}>
                        <Navbar title={"Bitcoin Staking"} />
                        <div className="max-w-7xl mt-8 sm:mt-12 md:mt-16 gap-x-[8rem] mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2">
                            <div className="p-3 mt-4 lg:p-6 flex flex-col">
                                <div className="text-lg md:text-xl text-primary mb-2 uppercase z-10">
                                    Current APR: {formattedApr}% Annually
                                </div>
                                <div className="text-4xl font-semibold font-urbanist md:text-5xl lg:text-6xl text-white mb-8 z-10">
                                    Make Your Bitcoin
                                    <br />
                                    Work For You
                                </div>
                                <div className="absolute right-[5%] sm:right-[30%] md:right-[5%] lg:right-[25%] top-0 bottom-[70%] md:bottom-[40%] h-20 w-20 md:h-32 md:w-32  lg:h-40 lg:w-40 m-auto">
                                    <img src={BTCBLogoSide} alt="BTCB Coin" />
                                </div>
                                <div className="absolute right-[8%] top-0 bottom-[11%] sm:right-[5%] md:right-[15%] lg:right-[5%] md:top-[10%] md:bottom-0 w-24 h-24  sm:w-32 sm:h-32 lg:h-52 lg:w-52 m-auto">
                                    <img src={BombLogoSide} alt="Bomb Coin" />
                                </div>
                                <div className="flex flex-row items-center relative text-md gap-x-2 gap-y-4 flex-wrap justify-evenly mb-6">
                                    <StakeInput
                                        glow
                                        outline
                                        className="flex-1"
                                        setStakeAmount={setStakeAmount}
                                        stakeAmount={stakeAmount}
                                    />
                                    <div className="flex min-w-fit">
                                        <a
                                            href="https://chat.bomb.app/hc/bomb-btc-help/en/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="relative inline-flex items-center px-6 py-2 border-2 border-primary shadow-sm font-medium rounded-full text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase"
                                        >
                                            Learn More
                                        </a>
                                    </div>
                                </div>
                                <div className="flex w-100 justify-center mb-6 gap-2">
                                    <div className="animate-breathing flex flex-col flex-1 relative inline-flex items-center px-6 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-primary">
                                        <span className="text-darkblue uppercase">
                                            Stake now to receive a 5% Bonus!
                                        </span>
                                        <span className="text-darkblue italic">Limited Time Offer!</span>
                                    </div>
                                    <div className="w-12 self-center">
                                        <a
                                            href="https://chat.bomb.app/hc/bomb-btc-help/en/deposits-and-withdrawals/14"
                                            target="_blank"
                                            rel="noreferrer"
                                            className=""
                                        >
                                            <QuestionMarkCircleIcon className="stroke-primary" />
                                        </a>
                                    </div>
                                </div>
                                <Calculation apr={apr} setCalcValue={setCalcValue} calcValue={calcValue} />
                            </div>
                            <div className="relative z-20 p-3 mt-4 lg:p-6 flex flex-col">
                                <ReactPlayer
                                    className="aspect-video cursor-pointer"
                                    loop
                                    width={"100%"}
                                    muted={true}
                                    controls={true}
                                    playing
                                    url="https://www.youtube.com/watch?v=3JZ7Gbsn1zo"
                                />
                                <div className="mt-6 flex justify-center">
                                    <button
                                        onClick={() => setOpenEmailPopup(true)}
                                        className="animate-breathing relative inline-flex items-center px-6 py-2 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary"
                                    >
                                        <span className="text-darkblue uppercase">Enter your email to learn more!</span>
                                    </button>
                                </div>
                                <div className="mt-8 flex md:block mb-4">
                                    <Scroller
                                        className="cursor-pointer md:absolute left-[-76px]"
                                        onClick={() => {
                                            scroller.scrollTo("maximise", {
                                                duration: 500,
                                                delay: 0,
                                                smooth: true,
                                                offset: -150,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="mt-16 sm:mt-20 md:mt-24 gap-y-16 gap-8 lg:gap-8 grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 flex-wrap font-semibold">
                                    <IconValue
                                        imgSrc={Lock}
                                        imgAlt="Lock"
                                        text={tvl?.[56]["bitbomb-bitbtc-btcb"].toLocaleString(undefined, {
                                            maximumFractionDigits: 0,
                                        })}
                                        unit={"$"}
                                        title="Total Value Locked"
                                    />
                                    <IconValue
                                        imgSrc={People}
                                        imgAlt="Community"
                                        text={
                                            portfolioTotals &&
                                            prices &&
                                            (
                                                (portfolioTotals?.totalLpRewarded +
                                                    portfolioTotals?.totalLpCompounded) *
                                                prices?.bombBtcLpUsdt
                                            )?.toLocaleString(undefined, {
                                                maximumFractionDigits: 0,
                                            })
                                        }
                                        title="Total Number Of Stakes"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="bg-no-repeat bg-contain bg-top relative"
                    style={{ backgroundImage: `url('${BombMaximiseBG}')` }}
                >
                    <div className="absolute left-0 right-0 top-[1rem] m-auto h-48 flex justify-center z-10">
                        <div className="animate-wiggle shadow-glow-lg shadow-primary h-16 w-16 pt-2 rounded-full self-center mt-[2rem]"></div>
                        <img
                            className="animate-wiggle h-48 absolute top-0 bottom-0 left-0 right-0 m-auto"
                            src={CenterBomb}
                            alt="Bomb"
                        />
                    </div>
                    <div className="mt-16 sm:mt-20 md:mt-24 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="p-3 mt-3 lg:p-6 lg:mt-12 flex flex-col">
                            <div id="maximise" className="mt-36 p-8 bg-black/30 text-center rounded backdrop-blur-sm">
                                <div className="text-2xl md:text-5xl font-urbanist text-primary font-semibold my-4">
                                    Maximise the full potential of your Bitcoin
                                </div>
                                <div className="text-white text-sm md:text-lg mb-8 leading-loose">
                                    BOMB Single Staking brings the high yields of decentralized finance to Bitcoin
                                    holders through the utilization of our underlying smart contract-based BOMB
                                    protocol. Put your Bitcoin holdings to work and enjoy earning high, sustainable
                                    yields without having to navigate the often perilous and confusing landscape of
                                    DeFi.
                                </div>
                                <div className="flex justify-center">
                                    <button
                                        onClick={() => {
                                            scroller.scrollTo("great", {
                                                duration: 500,
                                                delay: 0,
                                                smooth: true,
                                                offset: -25,
                                            });
                                        }}
                                        className="relative inline-flex items-center px-6 py-2 border-2 border-primary shadow-sm font-medium rounded-full text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase"
                                    >
                                        More Info
                                    </button>
                                </div>
                            </div>
                            <div className="mt-16 sm:mt-20 md:mt-24 grid gap-12 md:grid-cols-3 justify-items-center text-white">
                                <div className="flex flex-col items-center">
                                    <div className="mb-4 md:mb-8 text-4xl md:text-5xl lg:text-7xl whitespace-nowrap">
                                        Safe
                                    </div>
                                    <div className="mb-4 md:mb-8 bg-primary h-1 md:h-2 w-1/2"></div>
                                    <div className="text-small md:text-md text-center max-w-[66%] leading-loose">
                                        Sustainable yields through the BOMB protocol, which is pegged to Bitcoin, the
                                        largest and most stable crypto asset.
                                    </div>
                                </div>
                                <div className="flex flex-col items-center">
                                    <div className="mb-4 md:mb-8 text-4xl md:text-5xl lg:text-7xl whitespace-nowrap">
                                        High Yield
                                    </div>
                                    <div className="mb-4 md:mb-8 bg-primary h-1 md:h-2 w-1/2"></div>
                                    <div className="text-small md:text-md text-center max-w-[66%] leading-loose">
                                        Access the high yields generated by a unique and powerful DeFi protocol with
                                        none of the hassle.
                                    </div>
                                </div>
                                <div className="flex flex-col items-center">
                                    <div className="mb-4 md:mb-8 text-4xl md:text-5xl lg:text-7xl whitespace-nowrap">
                                        Simple
                                    </div>
                                    <div className="mb-4 md:mb-8 bg-primary h-1 md:h-2 w-1/2"></div>
                                    <div className="text-small md:text-md text-center max-w-[66%] leading-loose">
                                        Getting started is as simple as the click of a few buttons. Put your Bitcoin to
                                        work so you can sit back and relax.
                                    </div>
                                </div>
                            </div>
                            <div className="mt-16 sm:mt-20 md:mt-24 flex flex-col h-20 relative text-2xl hover:text-3xl hover:font-bold">
                                <div className="flex overflow-hidden h-20">
                                    <Marquee
                                        gradient={false}
                                        className="max-w-[98vw] overflow-hidden hover:font-normal hover:text-2xl transition-all duration-75 ease-linear italic text-slate-500/50 select-none"
                                    >
                                        <div className="pr-6">STAKE NOW</div>
                                        <div className="pr-6">STAKE NOW</div>
                                        <div className="pr-6">STAKE NOW</div>
                                        <div className="pr-6">STAKE NOW</div>
                                        <div className="pr-6">STAKE NOW</div>
                                        <div className="pr-6">STAKE NOW</div>
                                        <div className="pr-6">STAKE NOW</div>
                                        <div className="pr-6">STAKE NOW</div>
                                        <div className="pr-6">STAKE NOW</div>
                                        <div className="pr-6">STAKE NOW</div>
                                        <div className="pr-6">STAKE NOW</div>
                                    </Marquee>
                                </div>
                                <div className="self-center absolute h-full flex">
                                    <Link
                                        to={DashboardPageName}
                                        className="self-center z-10 bg-primary text-darkblue rounded-full px-6 py-3 font-medium shadow-glow shadow-primary w-32 whitespace-nowrap !text-base"
                                    >
                                        Stake Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative before:absolute before:rounded-full before:top-0 before:bottom-0 before:shadow-glow-xxl before:shadow-yellow-300 before:my-auto before:-ml-12 before:h-1">
                    <div className="max-w-7xl mx-auto mt-16 sm:mt-20 md:mt-24 px-4 sm:px-6 lg:px-8 relative">
                        <div className="p-3 mt-3 lg:p-6 lg:mt-12 flex flex-col">
                            <div className="grid gap-12 md:grid-cols-2">
                                <div id="great">
                                    <div className="text-white text-3xl md:text-4xl font-urbanist font-medium mb-8">
                                        Bitcoin's great. <br /> We love Bitcoin, but...
                                    </div>
                                    <div className="text-white text-small md:text-base mb-8 leading-loose">
                                        What if your Bitcoin could work for you? With BOMB your Bitcoin will earn you
                                        passive income at unbeatable rates allowing you to sit back and watch your
                                        Bitcoin grow daily.
                                    </div>
                                    <div className="text-white mb-8 leading-loose">
                                        Depositing your Bitcoin in our BTC Single Staking vault places it in a secure
                                        liquidity pool with BOMB, our native algocoin that itself is pegged to Bitcoin,
                                        so that you always maintain full Bitcoin exposure.
                                    </div>
                                    {/* <div className="flex justify-left">
                                        <button className="relative inline-flex items-center px-6 py-2 border-2 border-primary shadow-sm font-medium rounded-full text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase">
                                            Calculate your Forecast
                                        </button>
                                    </div> */}
                                </div>
                                <div className="">
                                    <div className="pl-[6%] text-2xl md:text-4xl text-white font-urbanist font-thin uppercase">
                                        Live APR :{" "}
                                        <span className="text-shadow text-primary font-bold">
                                            {formattedApr}% Annually
                                        </span>
                                    </div>
                                    <img src={Graph} alt="Graph" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative before:absolute before:rounded-full before:right-0 before:top-0 before:bottom-0 before:shadow-glow-xxl before:shadow-yellow-300 before:my-auto before:-mr-12 before:h-1">
                    <div className="max-w-7xl mt-16 sm:mt-20 md:mt-24 mx-auto px-4 sm:px-6 lg:px-8 relative">
                        <div className="p-3 mt-3 lg:p-6 lg:mt-12 flex flex-col">
                            <div className="grid gap-12 md:grid-cols-2">
                                <div className="max-w-[25rem] flex justify-self-center">
                                    <img src={Computer} alt="Computer showing BOMB Dashboard" />
                                </div>
                                <div>
                                    <div className="text-white text-3xl md:text-4xl font-urbanist mb-8 font-medium">
                                        Full control
                                        <br />
                                        at your fingertips
                                    </div>
                                    <div className="text-white text-small md:text-base leading-loose mb-8">
                                        Our proprietary dashboard provides full transparency over your investment and
                                        gives you exactly the information you need, when you need it.
                                    </div>
                                    <div className="text-white text-small md:text-base leading-loose mb-8">
                                        Manage every aspect of your investments in our industry-leading and
                                        easy-to-understand dashboard that keeps you in control of your money.
                                    </div>
                                    <div className="flex justify-left">
                                        <Link
                                            to={DashboardPageName}
                                            className="relative inline-flex items-center px-6 py-2 border-2 border-primary shadow-sm font-medium rounded-full text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase"
                                        >
                                            View Dashboard
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="p-3 mt-16 sm:mt-20 md:mt-24 lg:p-6 lg:mt-12 flex flex-col">
                        <div className="text-white mb-12 text-4xl font-urbanist md:text-7xl text-center">
                            Choose Your Path
                        </div>
                        <div className="grid gap-12 md:grid-cols-3">
                            <div className="flex flex-col items-center max-w-[80%] justify-self-center">
                                <img className="w-32 md:w-48 mb-4" src={BitCoinFront} alt="Bitcoin" />
                                <div className="text-white text-2xl font-urbanist font-medium mb-1 text-center">
                                    Bitcoin Staking
                                </div>
                                <div className="text-primary text-2xl font-urbanist font-medium uppercase mb-4">
                                    Easy
                                </div>
                                <div className="text-white text-small md:text-base text-center leading-loose mb-4">
                                    Bitcoin single staking is the simplest way to get involved with the BOMB ecosystem.
                                    Stake your Bitcoin and earn industry-leading APY returns on your Bitcoin.
                                </div>
                                <div className="flex w-full">
                                    <Link
                                        to={DashboardPageName}
                                        className="w-full text-center relative items-center px-6 py-2 border-2 border-primary shadow-sm font-medium rounded-full text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase"
                                    >
                                        Stake Now
                                    </Link>
                                </div>
                            </div>
                            <div className="flex items-center justify-center">
                                <img className="max-h-[8rem]" src={Swap} alt="Swap" />
                            </div>
                            <div className="flex flex-col items-center max-w-[80%] justify-self-center">
                                <img className="w-32 md:w-48 mb-4" src={BombCoinFront} alt="BOMB coin" />
                                <div className="text-white text-2xl font-urbanist font-medium mb-1 text-center">
                                    Bomb Money App
                                </div>
                                <div className="text-primary text-2xl font-urbanist font-medium uppercase mb-4">
                                    Advanced
                                </div>
                                <div className="text-white text-small md:text-base text-center leading-loose mb-4">
                                    The Bomb money app allows greater control over your investment strategy but requires
                                    more technical expertise to setup and manage.
                                </div>
                                <div className="flex w-full">
                                    <a
                                        href={BombFarmWebsiteName}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="w-full text-center relative items-center px-6 py-2 border-2 border-primary shadow-sm font-medium rounded-full text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase"
                                    >
                                        Open Farm App
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div
                        className="bg-no-repeat bg-contain bg-center mb-8 md:mb-16"
                        style={{ backgroundImage: `url('${Globe}')` }}
                    >
                        <div className="p-3 mt-3 lg:p-6 lg:mt-12 flex flex-col">
                            <div className="text-white text-3xl md:text-6xl font-urbanist text-center mb-4 md:mb-8 mt-16">
                                Become Part of Our Fast <br />
                                Growing Community
                            </div>
                            <div className="text-primary uppercase text-lg md:text-xl text-center mb-4 md:mb-8">
                                Learn and invest together
                            </div>
                            <div className="gap-x-8 gap-y-4 flex justify-center mb-8 md:mb-16 flex-wrap">
                                {/* <a href="#" target="_blank">
                                    <img className="h-12 w-12" src={SocialFacebook} alt="Facebook" />
                                </a> */}
                                <a href="https://t.me/bombmoneybsc" rel="noreferrer" target="_blank">
                                    <img className="h-12 w-12" src={SocialTelegram} alt="Telegram" />
                                </a>
                                <a href="https://twitter.com/BombMoneyBSC" rel="noreferrer" target="_blank">
                                    <img className="h-12 w-12" src={SocialTwitter} alt="Twitter" />
                                </a>
                                <a href="https://bombbshare.medium.com/" rel="noreferrer" target="_blank">
                                    <img className="h-12 w-12" src={SocialMedium} alt="Medium" />
                                </a>
                                <a href="https://discord.com/invite/94Aa4wSz3e" rel="noreferrer" target="_blank">
                                    <img className="h-12 w-12" src={SocialDiscord} alt="Discord" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="bg-no-repeat bg-cover bg-center mb-12"
                    style={{ backgroundImage: `url('${CtaBanner}')` }}
                >
                    <div className="p-3 mt-3 sm:p-12 lg:mt-12 flex flex-col">
                        <div className="grid gap-12 md:grid-cols-2">
                            <div className="text-white text-4xl lg:text-7xl max-w-[34rem] text-center md:text-left flex justify-self-center">
                                Light The Fuse On Your Bitcoin
                            </div>
                            <div className="flex flex-col justify-center align-center justify-self-center w-56">
                                <div className="text-white text-center text-xl md:text-2xl font-urbanist font-medium mb-4">
                                    Get Started Today
                                </div>
                                <div className="flex w-full">
                                    <Link
                                        to={DashboardPageName}
                                        className="w-full text-center relative items-center px-6 py-2 border-2 border-primary shadow-sm font-medium rounded-full text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary uppercase"
                                    >
                                        Stake Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="p-3 mt-3 lg:p-6 lg:mt-12 flex flex-col">
                        <div className="grid gap-12 md:grid-cols-2 mb-16">
                            <div className="">
                                <img className="h-10 mb-4" src={Logo} alt="Bomb Crypto Logo" />
                                <div className="text-white text-lg border-b-2 border-slate-500/50 pb-4 w-fit">
                                    <div>
                                        Bitcoin doesn’t need to be{" "}
                                        <span className="before:block before:absolute before:top-[55%] before:bottom-[17%] before:left-[-2px] before:right-0 before:bg-gradient-to-r before:from-[#E7C010] before:to-[#070c19] relative inline-block">
                                            <span className="relative text-white">passive</span>
                                        </span>
                                        ,
                                    </div>
                                    <div>
                                        De-Fi doesn’t need to be{" "}
                                        <span className="before:block before:absolute before:top-[55%] before:bottom-[17%] before:left-[-2px] before:right-0 before:bg-gradient-to-r before:from-[#E7C010] before:to-[#070c19] relative inline-block">
                                            <span className="relative text-white">confusing</span>
                                        </span>
                                        .
                                    </div>
                                </div>
                            </div>
                            <div className="grid gap-8 sm:grid-cols-2 text-white">
                                <div>
                                    <div className="border-b-2 border-slate-500/50 pb-4 text-md w-fit">Navigation</div>
                                    <div className="py-4 text-sm flex flex-col space-y-2">
                                        <div>
                                            <Link to="/">Home</Link>
                                        </div>
                                        <div>
                                            <Link to={DashboardPageName}>Dashboard</Link>
                                        </div>
                                        <div>
                                            <a href="https://bomb.farm/">Bomb Farming App</a>
                                        </div>
                                        <div>
                                            <a href="https://chat.bomb.app/hc/bomb-btc-help/en/">
                                                Bitcoin Staking Knowledge Base
                                            </a>
                                        </div>
                                        <div>
                                            <a href="https://docs.bomb.money/welcome-start-here/readme">About Bomb</a>
                                        </div>
                                        <div>
                                            <a href="https://t.me/bombmoneybsc">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-fit md:justify-self-end md:pr-8">
                                    <div className="border-b-2 border-slate-500/50 pb-4 text-md w-fit">Communities</div>
                                    <div className="py-4 text-sm flex flex-col space-y-2">
                                        <a href="https://discord.bomb.money/">English</a>
                                        <a href="https://discord.bomb.money/">Spanish</a>
                                        <a href="https://discord.bomb.money/">Chinese</a>
                                        <a href="https://discord.bomb.money/">Portuguese</a>
                                        <a href="https://discord.bomb.money/">Russian</a>
                                        <a href="https://discord.bomb.money/">Turkish</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between text-xs flex-wrap gap-4">
                            <div className="text-slate-400">&copy; 2022 Bomb.Money. All Rights Reserved</div>
                            <div className="underline text-slate-400 space-x-12">
                                <a href="https://chat.bomb.app/hc/bomb-btc-help/en/miscellaneous/18">
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default HomePage;
