import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AppSettings } from "./App";

interface Auth0ProviderWithHistoryProps {
    appSettings: AppSettings;
}
export const storageStakeName = "stake_amount";

const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = (props) => {
    const nav = useNavigate();

    const onRedirectCallback = (appState: any) => {
        localStorage.setItem(storageStakeName, appState.stakeAmount);
        nav(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={props.appSettings.Auth0.Domain}
            clientId={props.appSettings.Auth0.ClientId}
            audience={props.appSettings.Auth0.Audience}
            redirectUri={window.location.origin + "/dashboard"}
            onRedirectCallback={onRedirectCallback}
        >
            {props.children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
