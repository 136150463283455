import moment from "moment";
import React, { useCallback, useState } from "react";
import Moment from "react-moment";
import { Stake, StakeStatus, Prices } from "../../models/models";
import WithdrawPopup from "../withdraw/WithdrawPopup";

interface StakingHistoryProps {
    stake: Stake;
    prices: Prices | null;
}

interface Settings {
    statusIndicatorClasses: string;
    statusText: string;
    amountBtc: number;
    amountUsd: number;
}

function StakingHistory(props: StakingHistoryProps) {
    const [openWithdrawPopup, setOpenWithdrawPopup] = useState(false);

    const getSettings = useCallback(() => {
        if (props.stake.status !== undefined) {
            switch (props.stake.status) {
                case StakeStatus.INITIATED:
                    return {
                        statusIndicatorClasses: " shadow-glow-md shadow-[#FF4500] bg-[#FF4500]",
                        statusText: "Awaiting Payment",
                        amountBtc: props.stake.deposit.invoiceAmountBtc,
                        amountUsd: props.stake.deposit.invoiceAmountBtcUsd,
                    } as Settings;
                case StakeStatus.FAILED:
                    return {
                        statusIndicatorClasses: " bg-[#4F5F78]",
                        statusText: "Payment Failed",
                        amountBtc: props.stake.deposit.invoiceAmountBtc,
                        amountUsd: props.stake.deposit.invoiceAmountBtcUsd,
                    } as Settings;
                case StakeStatus.PENDING:
                    return {
                        statusIndicatorClasses: " shadow-glow-md shadow-[#FF4500] bg-[#FF4500]",
                        statusText: "Pending Confirmation",
                        amountBtc: props.stake.deposit.invoiceAmountBtc,
                        amountUsd: props.stake.deposit.invoiceAmountBtcUsd,
                    } as Settings;
                case StakeStatus.STAKED:
                    return {
                        statusIndicatorClasses: " shadow-glow-md shadow-primary bg-primary",
                        statusText: "Staked",
                        amountBtc: props.stake.btcStaked,
                        amountUsd: props.stake.btcUsdStaked,
                    } as Settings;
                case StakeStatus.UNSTAKED:
                    return {
                        statusIndicatorClasses: " bg-[#4F5F78]",
                        statusText: "Withdrawn",
                        amountBtc: props.stake.btcStaked,
                        amountUsd: props.stake.btcUsdStaked,
                    } as Settings;
            }
        }
    }, [props.stake]);

    const settings = getSettings();
    const todayOrUnstakedAt =
        props.stake.unstakedAt === null ||
        new Date(props.stake.unstakedAt).toISOString() === new Date("0001-01-01T00:00:00+00:00").toISOString()
            ? moment()
            : moment(props.stake.unstakedAt);

    return (
        <div
            className={
                "grid grid-flow-col auto-cols-6 gap-4 items-center bg-[#1C283C] rounded-lg px-4 py-2 h-16 relative"
            }
        >
            <div className="absolute left-0 top-0 bottom-0 bg-[#18202E] w-3 rounded-r-lg">
                <div
                    className={"absolute left-1 top-5 bottom-5 w-1 rounded full " + settings?.statusIndicatorClasses}
                ></div>
            </div>
            <div className="text-white text-xs text-center">
                <Moment format="MMM DD yyyy - HH:mm">{props.stake.stakedAt}</Moment>
                <div className="font-thin">{settings?.statusText}</div>
            </div>
            <div className="text-xs text-center">
                <div className="font-medium">
                    {settings?.amountBtc?.toLocaleString(undefined, {
                        maximumFractionDigits: 5,
                    })}{" "}
                    BTC
                </div>
                <div className="text-slate-500">
                    $
                    {settings?.amountUsd?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                    })}
                </div>
            </div>
            <div className="text-xs text-center">{"Flexible"}</div>
            <div className="text-xs text-center min-w-[8rem] gap-1 grid">
                { (moment(props.stake.stakedAt, 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'),'days') < 182) && (
                <>
                    {(props.stake.status === StakeStatus.STAKED || props.stake.status === StakeStatus.UNSTAKED) && (
                        <>
                            {props.stake.maturesAt && (
                                <div>
                                    <Moment format="MMM DD yyyy">{props.stake.maturesAt}</Moment>
                                </div>
                            )}
                            {props.stake.maturesAt && (
                                <div className="w-full max-w-[8rem] justify-self-center rounded-full bg-[#8895AB] h-2 relative">
                                    <div
                                        className={"absolute rounded-full bg-primary top-0 bottom-0"}
                                        style={{
                                            width:
                                                ((moment(props.stake.maturesAt).valueOf() - todayOrUnstakedAt.valueOf()) /
                                                    (moment(props.stake.stakedAt).valueOf() -
                                                        moment(props.stake.maturesAt).valueOf())) *
                                                100 +
                                                100 +
                                                "%",
                                        }}
                                    ></div>
                                </div>
                            )}
                            <div>
                                {props.stake.maturesAt && (
                                    <Moment duration={todayOrUnstakedAt} utc format="dd [D] - HH [H] - mm [M]">
                                        {moment(props.stake.maturesAt)}
                                    </Moment>
                                )}
                            </div>
                        </>
                    )}
                </>
                )}
            </div>
            <div className="text-xs text-center min-w-[7rem]">
                {(props.stake.status === StakeStatus.STAKED || props.stake.status === StakeStatus.UNSTAKED) && (
                    <>
                        <div className="text-primary">
                            {((props.stake.lpRewarded / props.stake.lpStaked) * 100).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                            })}
                            %
                        </div>
                        <div className="font-medium">
                            {props.stake.lpRewarded.toLocaleString(undefined, {
                                maximumFractionDigits: 6,
                            })}
                            <span className="text-[0.65rem]"> YB</span>BTC
                        </div>
                        <div className="text-slate-500">
                            $
                            {props.prices &&
                                (props.stake.lpRewarded * props.prices.bombBtcLpUsdt).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                })}
                        </div>
                    </>
                )}
            </div>
            <div className="flex gap-2 justify-center min-w-[11rem] place-content-center">
                {(props.stake.status === StakeStatus.STAKED || props.stake.status === StakeStatus.UNSTAKED) && (
                    <>
                        {props.prices && (
                            <>
                                <button
                                    className="border border-primary rounded-full px-3 text-primary font-semibold text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary disabled:border-[#707E96] disabled:text-[#1B2535] disabled:bg-[#707E96] "
                                    onClick={() => setOpenWithdrawPopup(true)}
                                    disabled={props.stake.status === StakeStatus.UNSTAKED}
                                >
                                    {moment().isAfter(props.stake.maturesAt) ? "Withdraw" : "Withdraw"}
                                </button>
                                <WithdrawPopup
                                    className="border border-primary rounded-full px-3 text-primary font-semibold text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary disabled:border-[#707E96] disabled:text-[#1B2535] disabled:bg-[#707E96]"
                                    stakeAmount={props.stake.lpStaked * props.prices.bombBtcLpUsdt}
                                    rewardAmount={props.stake.lpRewarded * props.prices.bombBtcLpUsdt}

                                    earlyWithdrawalPenaltyAmount={moment().add(-6, 'months').isAfter(props.stake.stakedAt) ? 0 : (0.0005 * props.prices.btcUsdt)}
                                    
                                    //earlyWithdrawalPenaltyAmount={0.0005 * props.prices.btcUsdt}
                                    forfeitAmount={
                                        props.stake.lpRewarded *
                                        props.prices.bombBtcLpUsdt *
                                        (moment.duration(moment(props.stake.maturesAt).diff(moment())).asDays() / 182.5)
                                    }
                                    open={openWithdrawPopup}
                                    setOpen={setOpenWithdrawPopup}
                                    stakeId={props.stake.id}
                                    isBeforeMaturity={moment().isBefore(props.stake.maturesAt)}
                                />
                            </>
                        )}
                    </>
                )}
                {props.stake.status === StakeStatus.INITIATED && (
                    <>
                        <a
                            href={props.stake.deposit.invoiceUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="border border-primary rounded-full px-3 text-primary font-semibold text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary"
                        >
                            Complete Deposit
                        </a>
                    </>
                )}
                {props.stake.status === StakeStatus.PENDING && (
                    <>
                        <a
                            href={props.stake.deposit.invoiceUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="border border-primary rounded-full px-3 text-primary font-semibold text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary"
                        >
                            View Progress
                        </a>
                    </>
                )}
            </div>
        </div>
    );
}

export default StakingHistory;
