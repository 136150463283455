import { useAuth0 } from "@auth0/auth0-react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { DashboardPageName } from "../../Constants";
import Logo from "../../images/bomb logo 1.png";
import { useGlobalContext } from "../../providers/GlobalContext";
import Transak from "../transak/Transak";
import Flame from "../../images/flame.png";

interface NavbarProps {
    title: string;
}

function Navbar(props: NavbarProps) {
    const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
    const { clearStoredAccessToken } = useGlobalContext();
    const { pathname } = useLocation();

    const isHomePage = pathname === "/";

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(" ");
    }
    interface dropDownNavItem {
        name: string;
        onClick?: () => void;
        href?: string;
        hidden?: boolean;
    }

    const userNavigation: dropDownNavItem[] = [
        {
            name: isAuthenticated ? "Logout" : "Login",
            onClick: isAuthenticated
                ? () => {
                      clearStoredAccessToken();
                      logout();
                  }
                : () => loginWithRedirect({ prompt: "login" }),
        }//,
        //  {
        //      name: "Register",
        //      onClick: () => loginWithRedirect({ screen_hint: "signup", prompt: "login" }),
        //      hidden: isAuthenticated,
        //  },
    ];

    return (
        <>
            <Disclosure as="nav" className="relative z-10">
                {({ open }) => (
                    <div className={open ? "bg-darkblue" : "transparent"}>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="flex justify-between h-16">
                                <div className="flex">
                                    <div className="-ml-2 mr-2 flex items-center md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                    <div className="flex-shrink-0 flex items-center">
                                        <div className="sm:border-r-[1px] border-white pr-3 min-w-[7.4rem] z-50">
                                            <Link to={"/"}>
                                                <img className="block h-10 w-auto " src={Logo} alt="Bomb Crypto Logo" />
                                            </Link>
                                        </div>
                                        {props.title && (
                                            <div className="overflow-hidden hidden sm:block">
                                                <div className="font-urbanist text-white pl-3 text-2xl font-extralight animate-slideIn">
                                                    {props.title}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-center flex space-x-3">
                                    {/*isHomePage && (
                                        <div className="flex-shrink-0 hidden md:block">
                                            <a
                                                href={
                                                    "https://chat.bomb.app/hc/bomb-btc-help/en/how-do-i-buy-bitcoin/6"
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                                type="button"
                                                className="relative inline-flex items-center px-6 py-2 border border-gray-500 shadow-sm text-sm font-medium rounded-full text-white bg-darkblue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-darkblue"
                                            >
                                                <span className="text-white uppercase">Buy BTC</span>
                                            </a>
                                        </div>
                                            )}
                                    {isHomePage && (
                                        <div className="flex-shrink-0 hidden md:block">
                                            <Link
                                                to={DashboardPageName}
                                                type="button"
                                                className="relative inline-flex items-center px-6 py-2 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary"
                                            >
                                                <span className="text-darkblue uppercase">Stake BTC</span>
                                            </Link>
                                        </div>
                                    )*/} 

                                    <div className="flex-shrink-0 hidden md:block">
                                        {isHomePage ? (
                                            <Link
                                                to={DashboardPageName}
                                                type="button"
                                                className="relative inline-flex items-center px-6 py-2 border border-gray-500 shadow-sm text-sm font-medium rounded-full text-white bg-darkblue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-darkblue"
                                            >
                                                <span className="text-white uppercase">Dashboard</span>
                                            </Link>
                                        ) : /*(
                                            <Transak className="relative inline-flex items-center pl-6 pr-4 py-2 border border-gray-500 shadow-sm text-sm font-medium rounded-full text-white bg-darkblue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-darkblue">
                                                <span className="text-white uppercase pr-2">Buy Bitcoin</span>
                                                <img className="h-4" src={Flame} alt="flame" />
                                            </Transak>
                                        )*/null}
                                    </div>
                                    <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                                        {/* Profile dropdown */}
                                        <Menu as="div" className="ml-3 relative">
                                            <div>
                                                <Menu.Button className="flex text-white text-sm items-center font-medium">
                                                    <span className="uppercase">My Account</span>
                                                    <ChevronDownIcon
                                                        className="h-4 w-4 fill-primary mt-1"
                                                        aria-hidden="true"
                                                    />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                                    {userNavigation.map((item) => (
                                                        <Menu.Item key={item.name}>
                                                            {({ active }) =>
                                                                item.hidden ? (
                                                                    <div key={item.name}></div>
                                                                ) : item.onClick ? (
                                                                    <button
                                                                        onClick={item.onClick}
                                                                        className={classNames(
                                                                            active ? "bg-gray-100" : "",
                                                                            "flex w-full px-4 py-2 text-sm text-gray-700 "
                                                                        )}
                                                                    >
                                                                        {item.name}
                                                                    </button>
                                                                ) : (
                                                                    <Link
                                                                        to={item.href ?? "#"}
                                                                        className={classNames(
                                                                            active ? "bg-gray-100" : "",
                                                                            "block px-4 py-2 text-sm text-gray-700"
                                                                        )}
                                                                    >
                                                                        {item.name}
                                                                    </Link>
                                                                )
                                                            }
                                                        </Menu.Item>
                                                    ))}
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="md:hidden">
                            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                                <div className="flex items-center flex gap-3 flex-wrap">
                                    {/* {isHomePage && (
                                        <a
                                            href={
                                                "https://chat.bomb.app/hc/bomb-btc-help/en/how-do-i-buy-bitcoin/6"
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            type="button"
                                            className="relative inline-flex items-center px-6 py-2 border border-gray-500 shadow-sm text-sm font-medium rounded-full text-white bg-darkblue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-darkblue"
                                        >
                                            <span className="text-white uppercase">Buy BTC</span>
                                        </a>
                                    )} */}
                                    {/* <button
                                        type="button"
                                        className="relative inline-flex items-center px-6 py-2 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary"
                                    >
                                        <span className="text-darkblue uppercase">Stake BTC</span>
                                    </button> */}
                                    {isHomePage ? (
                                        <Link
                                            to={DashboardPageName}
                                            type="button"
                                            className="relative inline-flex items-center px-6 py-2 border border-gray-500 shadow-sm text-sm font-medium rounded-full text-white bg-darkblue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-darkblue"
                                        >
                                            <span className="text-white uppercase">Dashboard</span>
                                        </Link>
                                    ) : /*(
                                        <Transak className="relative inline-flex items-center px-6 py-2 border border-gray-500 shadow-sm text-sm font-medium rounded-full text-white bg-darkblue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-darkblue">
                                            <span className="text-white uppercase">Buy Bitcoin</span>
                                        </Transak>
                                    )*/null}
                                </div>
                            </div>
                            <div className="pt-4 pb-3 border-t border-gray-700">
                                <div className="flex items-center px-5 sm:px-6">
                                    <span className="uppercase text-white">My Account</span>
                                </div>
                                <div className="mt-3 px-2 space-y-1 sm:px-3">
                                    {userNavigation.map((item) =>
                                        item.hidden ? (
                                            <div key={item.name}></div>
                                        ) : item.onClick ? (
                                            <Disclosure.Button
                                                key={item.name}
                                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                                            >
                                                <div onClick={item.onClick}>{item.name}</div>
                                            </Disclosure.Button>
                                        ) : (
                                            <Disclosure.Button
                                                key={item.name}
                                                as={"a"}
                                                href={item.href}
                                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        )
                                    )}
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </div>
                )}
            </Disclosure>
            <div className="max-w-7xl mx-auto  sm:px-6 lg:px-8">
                <ToastContainer className="relative w-full z-40" enableMultiContainer containerId="nav" />
            </div>
        </>
    );
}

export default Navbar;
